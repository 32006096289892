<template>
  <section class="signup-container-box">
    <div class="reg-rt-container">
      <!-- Componant : 래귤러 박스 컴포넌트 1-->
      <!-- <div>{{ regInfo.name }}</div>
      <div>{{ regInfo.email }}</div>
      <div>{{ regInfo.password }}</div>
      <div>{{ regInfo.passwordCheck }}</div>
      <div>{{ regInfo.phoneNum }}</div>
      <div>{{ regInfo.birth_date }}</div>
      <div>{{ regInfo.cm_number }}</div>
      <div>{{ regInfo.gender }}</div>
      <div>{{ regInfo.organization_id }}</div>
      <div>{{ regInfo.church_office_id }}</div>
      <div>{{ regInfo.schoolName }}</div>
      <div>{{ regInfo.grade }}</div>
      <div>{{ regInfo.internship_field_id }}</div>
      <div>{{ regInfo.address }}</div>
      <div>{{ regInfo.address_detail }}</div>
      <div>{{ regInfo.training }}</div> -->
      <!-- <div>{{ tempRegInfo.user_id }}</div> -->
      <!-- <div>{{ familyInfo.member1.rel }}</div>
      <div>{{ familyInfo.member1.name }}</div>
      <div>{{ familyInfo.member1.phoneNum }}</div>
      <div>{{ familyInfo.member2.rel }}</div>
      <div>{{ familyInfo.member2.name }}</div>
      <div>{{ familyInfo.member2.phoneNum }}</div>
      <div>{{ familyInfo.member3.rel }}</div>
      <div>{{ familyInfo.member3.name }}</div>
      <div>{{ familyInfo.member3.phoneNum }}</div>
      <div>{{ familyInfo.member4.rel }}</div>
      <div>{{ familyInfo.member4.name }}</div>
      <div>{{ familyInfo.member4.phoneNum }}</div>
      <div>{{ familyInfo.member5.rel }}</div>
      <div>{{ familyInfo.member5.name }}</div>
      <div>{{ familyInfo.member5.phoneNum }}</div>
      <div>{{ familyInfo.member6.rel }}</div>
      <div>{{ familyInfo.member6.name }}</div>
      <div>{{ familyInfo.member6.phoneNum }}</div> -->

      <!-- Componant : 컴포넌트 시작 -->
      <article class="reg-rt-a1">
        <!-- Title : 타이틀 박스 -->
        <div class="reg-rt-a1-b1">
          <div class="reg-rt-a1-b1-c1">일반정보</div>

          <div class="reg-rt-a1-b1-c2">General information</div>
        </div>
        <!-- Conent : 컨텐츠 박스 -->
        <div class="reg-rt-a1-b2">
          <!-- 컬럼 1 : 프로필 이미지 업로드 -->
          <div class="reg-rt-a1-b2-c1">
            <div class="image-preview" id="image-preview">
              <img src="../assets/images/apply_user_defalt.png" id="img" />
            </div>
            <div v-if="regUserType == 'RT'" class="profile-text-box">
              <a href="https://forms.gle/oiR49yW1fEAThoHN7" target="_blank">
                <p class="">예원렘넌트</p>
                <p class="">REMNANT</p>
                <p class="">사진 업로드</p>
              </a>
            </div>
            <div v-if="regUserType == 'MI'" class="profile-text-box">
              <p class="">교역자</p>
              <p class="">MINISTER</p>
              <p class="">사진 업로드</p>
            </div>
            <div v-if="regUserType == 'TH'" class="profile-text-box">
              <p class="">기관교사</p>
              <p class="">TEACHER</p>
              <p class="">사진 업로드</p>
            </div>
            <div v-if="regUserType == 'PR'" class="profile-text-box">
              <p class="">전문산업인</p>
              <p class="">PROFESSIONAL</p>
              <p class="">사진 업로드</p>
            </div>
            <div v-if="regUserType == 'OF'" class="profile-text-box">
              <p class="">장학 & 인턴쉽 사역자</p>
              <p class="">OFFICER</p>
              <p class="">사진 업로드</p>
            </div>
            <!-- <input type="file" id="real-input" class="image_inputType_file" accept="img/*" required multiple> -->
          </div>
          <!-- 컬럼 2 : 회원가입 정보 입력 -->
          <div class="reg-rt-a1-b2-c2">
            <!-- 컬럼2-로우1 -->
            <div class="reg-rt-a1-b2-c2-d1">
              <!-- 컬럼2-로우1-컬럼1 : 좌상단 -->
              <div class="reg-rt-a1-b2-c2-d1-e1">
                <p>
                  <label for="name">이름<span class="haveto">*</span></label>
                  <input
                    v-model="regInfo.name"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="이름을 입력하세요"
                  />
                </p>
                <p>
                  <label for="email">Email<span class="haveto"> *</span></label>
                  <input
                    v-model="regInfo.email"
                    @change="checkEmail"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email 을 입력하세요"
                  />
                </p>
                <p>
                  <label for="password"
                    >비밀번호<span class="haveto"> *</span></label
                  >
                  <input
                    v-model="regInfo.password"
                    @change="checkPassword"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="숫자와 문자 포함 6~12자리"
                  />
                </p>
                <p>
                  <label for="password"
                    >비밀번호 확인<span class="haveto"> *</span></label
                  >
                  <input
                    v-model="regInfo.passwordCheck"
                    type="password"
                    id="password-re"
                    name="password-re"
                    placeholder="비밀번호를 확인합니다"
                  />
                </p>
                <p v-if="regUserType == 'RT'">
                  <label for="tel"
                    >전화번호<span class="haveto"> *</span></label
                  >
                  <input
                    v-model="regInfo.phoneNum"
                    type="tel"
                    id="tel"
                    name="tel"
                    placeholder="전화번호를 입력하세요"
                  />
                </p>
                <p>
                  <label for="birth_date"
                    >생년월일8자리<span class="haveto"> *</span></label
                  >
                  <input
                    v-model="regInfo.birth_date"
                    @change="checkBirthDate"
                    type="number"
                    id="birth_date"
                    name="birth_date"
                    placeholder="예시 : 19891001"
                  />
                </p>
              </div>
              <!-- 컬럼2-로우1-컬럼1 : 우상단 -->
              <div class="reg-rt-a1-b2-c2-d1-e2">
                <p>
                  <label for="gender">성별<span class="haveto"> *</span></label>
                  <select
                    v-model="regInfo.gender"
                    id="gender"
                    name="gender"
                    placeholder="성별 입력하세요"
                  >
                    <option value="">성별을 선택하세요</option>
                    <option value="M">남자</option>
                    <option value="F">여자</option>
                  </select>
                </p>
                <p>
                  <label for="department"
                    >소속기관<span class="haveto"> *</span></label
                  >
                  <select
                    v-model="regInfo.organization_id"
                    id="department"
                    name="department"
                    placeholder="소속 기관을 선택하세요"
                  >
                    <option value="">소속기관을 선택하세요</option>
                    <option value="1">초등부</option>
                    <option value="2">소년부</option>
                    <option value="3">중등부</option>
                    <option value="4">고등부</option>
                    <option value="5">대학국</option>
                    <option value="6">일본어 예배부</option>
                    <option value="7">러시아어 예배부</option>
                    <option value="8">영어 예배부</option>
                    <option value="9">중국어 예배부</option>
                    <option value="10">스페인어 예배부</option>
                    <option value="11">인도네시아어 예배부</option>
                    <option value="12">사랑교회</option>
                    <option value="13">농인교회</option>
                    <option value="14">드림교회</option>
                    <option value="15">기타</option>
                  </select>
                </p>
                <p>
                  <label for="ch-office">직분</label>
                  <select
                    v-model="regInfo.church_office_id"
                    id="internship"
                    name="internship"
                    placeholder="성별 입력하세요"
                  >
                    <option value="">직분을 선택하세요</option>
                    <option value="1">목사</option>
                    <option value="2">준목</option>
                    <option value="3">전도사</option>
                    <option value="4">장로</option>
                    <option value="5">권사</option>
                    <option value="6">집사</option>
                    <option value="7">성도</option>
                    <option value="8">렘넌트교사</option>
                    <option value="0">렘넌트</option>
                    <option value="9">렘넌트 | 회장</option>
                    <option value="10">렘넌트 | 부회장</option>
                    <option value="11">렘넌트 | 총무</option>
                    <option value="12">렘넌트 | 서기</option>
                    <option value="13">렘넌트 | 회계</option>
                    <option value="14">렘넌트 | 국장</option>
                    <option value="15">렘넌트 | 그룹장</option>
                    <option value="16">렘넌트 | 순장</option>
                    <option value="17">렘넌트 | EBS</option>
                    <option value="18">기타</option>
                  </select>
                </p>
                <p v-if="regUserType == 'RT'">
                  <label for="school">학교명</label>
                  <input
                    v-model="regInfo.schoolName"
                    type="text"
                    id="school"
                    name="school"
                    placeholder="학교명을 입력하세요"
                  />
                </p>
                <p v-if="regUserType == 'RT'">
                  <label for="grade">학년</label>
                  <select v-model="regInfo.grade" id="grade" name="grade">
                    <option value="">학년을 선택하세요</option>
                    <option value="1">1학년</option>
                    <option value="2">2학년</option>
                    <option value="3">3학년</option>
                    <option value="4">4학년</option>
                    <option value="5">5학년</option>
                    <option value="6">6학년</option>
                    <option value="7">7학년</option>
                    <option value="8">8학년</option>
                    <option value="9">9학년</option>
                    <option value="0">10학년</option>
                  </select>
                </p>
                <p v-if="regUserType == 'RT'">
                  <label for="internship">인턴쉽 분야</label>
                  <select
                    v-model="regInfo.internship_field_id"
                    id="internship"
                    name="internship"
                    placeholder="성별 입력하세요"
                  >
                    <option value="">진행한 인턴쉽 분야를 선택하세요</option>
                    <option value="1">공무원그룹 | 군,경찰</option>
                    <option value="2">공무원그룹 | 행정공무원</option>
                    <option value="3">공무원그룹 | 법률,정치,외교</option>
                    <option value="4">교육연구그룹 | 유아유치</option>
                    <option value="5">교육연구그룹 | 중등교사</option>
                    <option value="6">교육연구그룹 | 교수 & 교육산업</option>
                    <option value="7">미디어그룹 | 스트리머 BJ</option>
                    <option value="8">미디어그룹 | 성우아나운서</option>
                    <option value="9">미디어그룹 | 사진영상편집(디자인)</option>
                    <option value="10">문화예술1그룹 | 미술</option>
                    <option value="11">문화예술1그룹 | 패션</option>
                    <option value="12">문화예술1그룹 | 음악</option>
                    <option value="13">문화예술1그룹 | 작가</option>
                    <option value="14">문화예술2그룹 | 스포츠</option>
                    <option value="15">문화예술2그룹 | 공연</option>
                    <option value="16">문화예술2그룹 | 연예</option>
                    <option value="17">비즈니스1&선교 | 학문연구</option>
                    <option value="18">비즈니스1&선교 | 산업디자인</option>
                    <option value="19">비즈니스1&선교 | IT</option>
                    <option value="20">비즈니스1&선교 | 과학기술</option>
                    <option value="21">비즈니스2&선교 | 식품산업</option>
                    <option value="22">비즈니스2&선교 | 스타트업</option>
                    <option value="23">비즈니스2&선교 | 뷰티산업</option>
                    <option value="24">비즈니스2&선교 | 서비스</option>
                    <option value="25">사회복지그룹 | 사회복지</option>
                    <option value="26">사회복지그룹 | 상담치유</option>
                    <option value="27">사회복지그룹 | NGO</option>
                    <option value="28">의료그룹 | 의료(약사)</option>
                    <option value="29">의료그룹 | 간호</option>
                    <option value="30">선교그룹 | 선교</option>
                  </select>
                </p>
              </div>
            </div>
            <!-- 컬럼2-로우2 : 하단 -->
            <div v-if="regUserType == 'RT'" class="reg-rt-a1-b2-c2-d2">
              <div class="reg-rt-a1-b2-c2-d2-e1">
                <p>
                  <label for="address"
                    >주소<span class="haveto"> *</span></label
                  >
                  <input
                    v-model="regInfo.address"
                    type="text"
                    id="address_kakao"
                    name="address"
                    placeholder="주소를 입력하세요"
                  />
                  <input
                    v-model="regInfo.address_detail"
                    type="text"
                    name="address_detail"
                    placeholder="앞쪽에 모두 입력하세요"
                    disabled
                  />
                </p>
              </div>
              <div class="reg-rt-a1-b2-c2-d2-e2">
                <p>
                  <label for="training">본부훈련</label>
                  <label for="tr-1">1차 합숙</label>
                  <input
                    v-model="regInfo.training"
                    value="1"
                    type="checkbox"
                    id="tr-1"
                    name="tr-1"
                  />
                  <label for="tr-2">팀 합숙</label>
                  <input
                    v-model="regInfo.training"
                    value="2"
                    type="checkbox"
                    id="tr-2"
                    name="tr-2"
                  />
                  <label for="tr-3">현장 캠프</label>
                  <input
                    v-model="regInfo.training"
                    value="3"
                    type="checkbox"
                    id="tr-3"
                    name="tr-3"
                  />
                  <label for="tr-4">전문 학습</label>
                  <input
                    v-model="regInfo.training"
                    value="4"
                    type="checkbox"
                    id="tr-4"
                    name="tr-4"
                  />
                  <label for="tr-5">전도 학습</label>
                  <input
                    v-model="regInfo.training"
                    value="5"
                    type="checkbox"
                    id="tr-5"
                    name="tr-5"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <!-- Componant : 래귤러 박스 컴포넌트 2-->
      <!-- Componant : 컴포넌트 시작 -->
      <article v-if="regUserType == 'RT'" class="reg-rt-a2">
        <!-- Title : 타이틀 박스 -->
        <div class="reg-rt-a2-b1">
          <div class="reg-rt-a2-b1-c1">가족정보</div>
          <div class="reg-rt-a2-b1-c2">Family information</div>
        </div>
        <!-- Conent : 컨텐츠 박스 -->
        <div class="reg-rt-a2-b2">
          <!-- 테이블 타이틀 -->
          <div class="reg-rt-a2-b2-c1">
            <div class="fam-table-title">관계</div>
            <div class="fam-table-title">이름</div>
            <div class="fam-table-title">연락처</div>
          </div>
          <!-- 테이블 로우 -->

          <!-- 로우 1 -->
          <div class="reg-rt-a2-b2-c2">
            <p>
              <select
                v-model="familyInfo.member1.rel"
                id="f1-rel"
                name="f1-rel"
              >
                <option value="">관계를 선택하세요</option>
                <option value="GF">할아버지</option>
                <option value="GM">할머니</option>
                <option value="FA">아버지</option>
                <option value="MA">어머니</option>
                <option value="BR">형재</option>
                <option value="SI">자매</option>
                <option value="ET">기타</option>
              </select>
            </p>
            <p>
              <input
                v-model="familyInfo.member1.name"
                type="text"
                id="f1-name"
                name="f1-name"
                placeholder="이름 입력"
              />
            </p>
            <p>
              <input
                v-model="familyInfo.member1.phoneNum"
                type="text"
                id="f1-tel"
                name="f1-tel"
                placeholder="- 제외하고 입력"
              />
            </p>
          </div>
          <!-- 로우 2 -->
          <div class="reg-rt-a2-b2-c2">
            <p>
              <select
                v-model="familyInfo.member2.rel"
                id="f2-rel"
                name="f2-rel"
              >
                <option value="">관계를 선택하세요</option>
                <option value="GF">할아버지</option>
                <option value="GM">할머니</option>
                <option value="FA">아버지</option>
                <option value="MA">어머니</option>
                <option value="BR">형재</option>
                <option value="SI">자매</option>
                <option value="ET">기타</option>
              </select>
            </p>
            <p>
              <input
                v-model="familyInfo.member2.name"
                type="text"
                id="f2-name"
                name="f2-name"
                placeholder="이름 입력"
              />
            </p>

            <p>
              <input
                v-model="familyInfo.member2.phoneNum"
                type="text"
                id="f2-tel"
                name="f2-tel"
                placeholder="- 제외하고 입력"
              />
            </p>
          </div>
          <!-- 로우 3 -->
          <div class="reg-rt-a2-b2-c2">
            <p>
              <select
                v-model="familyInfo.member3.rel"
                id="f3-rel"
                name="f3-rel"
              >
                <option value="">관계를 선택하세요</option>
                <option value="GF">할아버지</option>
                <option value="GM">할머니</option>
                <option value="FA">아버지</option>
                <option value="MA">어머니</option>
                <option value="BR">형재</option>
                <option value="SI">자매</option>
                <option value="ET">기타</option>
              </select>
            </p>
            <p>
              <input
                v-model="familyInfo.member3.name"
                type="text"
                id="f3-name"
                name="f3-name"
                placeholder="이름 입력"
              />
            </p>

            <p>
              <input
                v-model="familyInfo.member3.phoneNum"
                type="text"
                id="f3-tel"
                name="f3-tel"
                placeholder="- 제외하고 입력"
              />
            </p>
          </div>
          <!-- 로우 4 -->
          <div class="reg-rt-a2-b2-c2">
            <p>
              <select
                v-model="familyInfo.member4.rel"
                id="f4-rel"
                name="f4-rel"
              >
                <option value="">관계를 선택하세요</option>
                <option value="GF">할아버지</option>
                <option value="GM">할머니</option>
                <option value="FA">아버지</option>
                <option value="MA">어머니</option>
                <option value="BR">형재</option>
                <option value="SI">자매</option>
                <option value="ET">기타</option>
              </select>
            </p>
            <p>
              <input
                v-model="familyInfo.member4.name"
                type="text"
                id="f4-name"
                name="f4-name"
                placeholder="이름 입력"
              />
            </p>

            <p>
              <input
                v-model="familyInfo.member4.phoneNum"
                type="text"
                id="f4-tel"
                name="f4-tel"
                placeholder="- 제외하고 입력"
              />
            </p>
          </div>
          <!-- 로우 5 -->
          <div class="reg-rt-a2-b2-c2">
            <p>
              <select
                v-model="familyInfo.member5.rel"
                id="f5-rel"
                name="f5-rel"
              >
                <option value="">관계를 선택하세요</option>
                <option value="GF">할아버지</option>
                <option value="GM">할머니</option>
                <option value="FA">아버지</option>
                <option value="MA">어머니</option>
                <option value="BR">형재</option>
                <option value="SI">자매</option>
                <option value="ET">기타</option>
              </select>
            </p>
            <p>
              <input
                v-model="familyInfo.member5.name"
                type="text"
                id="f5-name"
                name="f5-name"
                placeholder="이름 입력"
              />
            </p>

            <p>
              <input
                v-model="familyInfo.member5.phoneNum"
                type="text"
                id="f5-tel"
                name="f5-tel"
                placeholder="- 제외하고 입력"
              />
            </p>
          </div>
          <!-- 로우 6 -->
          <div class="reg-rt-a2-b2-c2">
            <p>
              <select
                v-model="familyInfo.member6.rel"
                id="f6-rel"
                name="f6-rel"
              >
                <option value="">관계를 선택하세요</option>
                <option value="GF">할아버지</option>
                <option value="GM">할머니</option>
                <option value="FA">아버지</option>
                <option value="MA">어머니</option>
                <option value="BR">형재</option>
                <option value="SI">자매</option>
                <option value="ET">기타</option>
              </select>
            </p>
            <p>
              <input
                v-model="familyInfo.member6.name"
                type="text"
                id="f6-name"
                name="f6-name"
                placeholder="이름 입력"
              />
            </p>

            <p>
              <input
                v-model="familyInfo.member6.phoneNum"
                type="text"
                id="f6-tel"
                name="f6-tel"
                placeholder="- 제외하고 입력"
              />
            </p>
          </div>
        </div>
      </article>
      <router-link style="text-decoration: none" to="">
        <!-- <button @click="$store.dispatch('login', 'hi')">회원가입</button> -->
        <button @click="registerUser">회원가입</button>
        <!-- registerUser -->
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import axios from 'axios';

export default {
  name: 'SignUp',
  data() {
    return {
      regstatus: false,
      tempRegInfo: {
        user_id: '0',
      },
      regInfo: {
        // user table
        name: '',
        email: '',
        password: '',
        passwordCheck: '',
        phoneNum: '',
        birth_date: '',
        gender: '',
        address: '',
        address_detail: '',
        user_type: 'RT',
        organization_id: '',
        church_office_id: '',
        internship_field_id: '',
        // school table
        schoolName: '',
        grade: '',
        location: 'n',
        // traning table
        training: [],
      },
      // family table
      familyInfo: {
        member1: {
          rel: '',
          name: '',
          phoneNum: '',
        },
        member2: {
          rel: '',
          name: '',
          phoneNum: '',
        },
        member3: {
          rel: '',
          name: '',
          phoneNum: '',
        },
        member4: {
          rel: '',
          name: '',
          phoneNum: '',
        },
        member5: {
          rel: '',
          name: '',
          phoneNum: '',
        },
        member6: {
          rel: '',
          name: '',
          phoneNum: '',
        },
      },
      addr1: '',
      addr2: '',
      // 유저 데이터 - 나중에
    };
  },
  computed: {
    ...mapState([
      // 텝 네비게이션 상태변수
      'topNavStats',
      // 유저타입 상태변수
      'regUserType',
      //탑 네비게이션 : Sub 변수
      'topNavKoTitle',
      'topNavEnTitle',
    ]),
  },
  methods: {
    ...mapActions(['login']),
    ...mapMutations([
      // 변경함수 : 탑 네비게이션 상태변수
      'chaingeTopNavMain',
      'chaingeTopNavSub',
      'chaingeTopNavLogin',
      'chaingeTopNavNone',
      // 변경함수 : 탑 네비게이션 서브 타이틀 변경
      'chaingeSubTitleSignUp',
      'chaingeSubTitleSignUpRt',
      'chaingeSubTitleSignUpMI',
      'chaingeSubTitleSignUpTH',
      'chaingeSubTitleSignUpPR',
      'chaingeSubTitleSignUpOF',
      // 변경함수 : 푸터 상태 변경
      'chaingeFooterMain',
      'chaingeFooterNone',
      // 변경함수 : 유저타입 상태변수
      'regUserTyepRT',
      'regUserTyepMI',
      'regUserTyepTH',
      'regUserTyepPR',
      'regUserTyepOF',
    ]),
    //알림
    showAlert() {
      // Use sweetalert2
      this.$swal('회원가입이 정상정으로 완료되었습니다!');
    },
    alertFillBlank() {
      this.$swal('필수입력사항 누락', '필수입력란을 기입해주세요', 'error');
      return;
    },
    alertPwInconsistency() {
      this.$swal(
        '비밀번호 불일치',
        '입력하신 비밀번호가 일치하지 않습니다',
        'error'
      );
      return;
    },
    alertWrongPw() {
      this.$swal(
        '잘못된 비밀번호 양식입니다',
        '숫자와 문자 포함 6~12자리 입력',
        'error'
      );
      return;
    },
    alertWrongEmail() {
      this.$swal(
        '잘못된 Email 양식입니다',
        '올바른 Email 양식을 입력하세요',
        'error'
      );
      return;
    },
    alertWrongBirthDate() {
      this.$swal(
        '잘못된 생년월일 양식입니다',
        '8자리 생년월일을 입력하세요 ex)19891001',
        'error'
      );
      return;
    },
    alertFailReg() {
      this.$swal(
        '회원가입에 실패하였습니다',
        '관리자에게 문의하세요 tel.010-3383-4177',
        'error'
      );
    },
    alertSuccessReg() {
      this.$swal(
        '회원가입 성공',
        '회원가입에 성공하셨습니다. 로그인 해주세요',
        'success'
      );
    },

    //점검
    checkBlank() {
      if (this.regInfo.name.trim() === '') {
        this.alertFillBlank();
        return false;
      }
      if (this.regInfo.email.trim() === '') {
        this.alertFillBlank();
        return false;
      }
      if (this.regInfo.password.trim() === '') {
        this.alertFillBlank();
        return false;
      }
      if (this.regInfo.phoneNum.trim() === '') {
        this.alertFillBlank();
        return false;
      }
      if (this.regInfo.birth_date === '') {
        this.alertFillBlank();
        return false;
      }
      if (this.regInfo.gender.trim() === '') {
        this.alertFillBlank();
        return false;
      }
      if (this.regInfo.organization_id.trim() === '') {
        this.alertFillBlank();
        return false;
      }
      if (this.regInfo.address.trim() === '') {
        this.alertFillBlank();
        return false;
      }
    },
    checkEmail() {
      const passRule =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      if (!passRule.test(this.regInfo.email)) {
        this.regInfo.email = '';
        this.alertWrongEmail();
      }
    },
    checkPassword() {
      //숫자와 문자 포함 형태의 6~12자리 이내의 암호 정규식
      const passRule = /^[A-Za-z0-9]{6,12}$/;
      if (!passRule.test(this.regInfo.password)) {
        this.regInfo.password = '';
        this.alertWrongPw();
      }
    },
    checkBirthDate() {
      //8자리 생년월일 EX 19891001
      const passRule =
        /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;
      if (!passRule.test(this.regInfo.birth_date)) {
        this.regInfo.birth_date = '';
        this.alertWrongBirthDate();
      }
    },
    // 비밀번호 암호와
    // createSalt() {
    //   new Promise((resolve, reject) => {
    //     crypto.randomBytes(64, (err, buf) => {
    //       if (err) reject(err);
    //       resolve(buf.toString('base64'));
    //     });
    //   });
    // },

    // createHashedPassword(plainPassword) {
    //   new Promise(async (resolve, reject) => {
    //     const salt = await createSalt();
    //     crypto.pbkdf2(plainPassword, salt, 9999, 64, 'sha512', (err, key) => {
    //       if (err) reject(err);
    //       resolve({ password: key.toString('base64'), salt });
    //     });
    //   });
    // },

    // 비동기함수

    async registerUser() {
      // 비어있는 항목이 있으면 알려준다
      console.log('빈칸체크!');
      if (this.checkBlank() === false) {
        return;
      }
      // 정규식을 통해 데이터의 형식을 검사한다
      console.log('Email 체크!');
      if (this.checkEmail() === false) {
        return;
      }
      console.log('비밀번호 체크!');
      if (this.checkPassword() === false) {
        return;
      }
      console.log('생년월일 체크!');
      if (this.checkBirthDate() === false) {
        return;
      }

      // 유저생성 -> 받아온 유저 아이디를 가지고 다음의 과정을 진행한다
      console.log('유저생성 시작!');
      const tempDate = this.tempRegInfo;

      await axios
        .post('https://www.yewon-remnant.net/api/users', {
          name: this.regInfo.name,
          email: this.regInfo.email,
          password: this.regInfo.password,
          birth_date: this.regInfo.birth_date,
          gender: this.regInfo.gender,
          address: this.regInfo.address,
          address_detail: this.regInfo.address_detail,
          user_type: this.regInfo.user_type,
          phone_number: this.regInfo.phoneNum,
          cm_number: '',
          organization_id: this.regInfo.organization_id,
          church_office_id: this.regInfo.church_office_id,
          internship_field_id: this.regInfo.internship_field_id,
          authority_id: this.regInfo.authority_id,
        })
        .then(function (res) {
          console.log(res);
          console.log(res.status);
          console.log(res.data.id);
          tempDate.user_id = res.data.id;
          console.log(tempDate.user_id);
        })
        .catch(function (err) {
          console.log(err);
          this.alertFailReg();
          return false;
        });

      // 학교정보 포스트 -> 애러 -> 알럿 & 처음부터 다시 진행
      console.log('학교생성 시작!');
      if (this.regInfo.schoolName !== '') {
        await axios
          .post('https://www.yewon-remnant.net/api/schools', {
            sc_name: this.regInfo.schoolName,
            grade: this.regInfo.grade,
            location: this.regInfo.location,
            user_id: tempDate.user_id,
          })
          .then(function (res) {
            console.log(res);
          })
          .catch(function (err) {
            console.log(err);
            this.alertFailReg();
            return false;
          });
      }

      // 훈련정보 포스트 -> 애러 -> 알럿 & 처음부터 다시 진행
      console.log('훈련정보생성 시작!');
      if (this.regInfo.training !== []) {
        for (let i = 0; i < this.regInfo.training.length; i++) {
          const training_id = this.regInfo.training[i];
          await axios
            .post('https://www.yewon-remnant.net/api/uhtrainings', {
              // 유저 생성이 완료된 이후에 진행되어야함
              user_id: tempDate.user_id,
              training_id: training_id,
            })
            .then(function (res) {
              console.log(res);
            })
            .catch(function (err) {
              console.log(err);
              this.alertFailReg();
            });
        }
      }

      // 가족정보 포스트  -> 애러 -> 알럿 & 처음부터 다시 진행
      console.log('가족정보 시작!');
      if (this.familyInfo.member1.rel !== '') {
        await axios
          .post('https://www.yewon-remnant.net/api/familys', {
            relationship: this.familyInfo.member1.rel,
            fm_name: this.familyInfo.member1.name,
            phone_number: this.familyInfo.member1.phoneNum,
            user_id: tempDate.user_id,
          })
          .then(function (res) {
            console.log(res);
          })
          .catch(function (err) {
            console.log(err);
            this.alertFailReg();
          });
      }
      if (this.familyInfo.member2.rel !== '') {
        await axios
          .post('https://www.yewon-remnant.net/api/familys', {
            relationship: this.familyInfo.member2.rel,
            fm_name: this.familyInfo.member2.name,
            phone_number: this.familyInfo.member2.phoneNum,
            user_id: tempDate.user_id,
          })
          .then(function (res) {
            console.log(res);
          })
          .catch(function (err) {
            console.log(err);
            this.alertFailReg();
          });
      }
      if (this.familyInfo.member3.rel !== '') {
        await axios
          .post('https://www.yewon-remnant.net/api/familys', {
            relationship: this.familyInfo.member3.rel,
            fm_name: this.familyInfo.member3.name,
            phone_number: this.familyInfo.member3.phoneNum,
            user_id: tempDate.user_id,
          })
          .then(function (res) {
            console.log(res);
          })
          .catch(function (err) {
            console.log(err);
            this.alertFailReg();
          });
      }
      if (this.familyInfo.member4.rel !== '') {
        await axios
          .post('https://www.yewon-remnant.net/api/familys', {
            relationship: this.familyInfo.member4.rel,
            fm_name: this.familyInfo.member4.name,
            phone_number: this.familyInfo.member4.phoneNum,
            user_id: tempDate.user_id,
          })
          .then(function (res) {
            console.log(res);
          })
          .catch(function (err) {
            console.log(err);
            this.alertFailReg();
          });
      }
      if (this.familyInfo.member5.rel !== '') {
        await axios
          .post('https://www.yewon-remnant.net/api/familys', {
            relationship: this.familyInfo.member5.rel,
            fm_name: this.familyInfo.member5.name,
            phone_number: this.familyInfo.member5.phoneNum,
            user_id: tempDate.user_id,
          })
          .then(function (res) {
            console.log(res);
          })
          .catch(function (err) {
            console.log(err);
            this.alertFailReg();
          });
      }
      if (this.familyInfo.member6.rel !== '') {
        await axios
          .post('https://www.yewon-remnant.net/api/familys', {
            relationship: this.familyInfo.member6.rel,
            fm_name: this.familyInfo.member6.name,
            phone_number: this.familyInfo.member6.phoneNum,
            user_id: tempDate.user_id,
          })
          .then(function (res) {
            console.log(res);
          })
          .catch(function (err) {
            console.log(err);
            this.alertFailReg();
          });
      }
      // 정상종료
      this.regstatus = true;
      this.chaingeTopNavNone();
      this.chaingeSubTitleSignUpRt();
      this.regUserTyepRT();
      this.chaingeFooterNone();
      this.alertSuccessReg();
      console.log('User Reg Complit!');
      this.$router.push({ name: 'Login' }, function () {
        console.log('/board/${no} 호출 완료');
      });
    },
  },
};
</script>

<style lang="scss">
@import '../design/init.scss';
@import '../design/_variables.scss';
@import '../design/_mixins.scss';
@import '../design/_functions.scss';

$title-font-size: 14px;
$input-font-size: 12px;
$input-height: 40px;

section.signup-container-box {
  div.reg-rt-container {
    z-index: -10;
    @include setBox(1200px, auto, 0, 0, auto, none);
    // Componant : 래귤러 박스 컴포넌트 1
    // Componant : 컴포넌트 시작
    article.reg-rt-a1 {
      // @include debugRed();
      @include setBox(1200px, 600px, 0, 0, 0, $bg-lv-06);
      margin-top: 70px;
      display: grid;
      grid-template-rows: 70px 1fr;
      // Title : 타이틀 박스
      div.reg-rt-a1-b1 {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
        box-shadow: 2px 2px 2px 2px rgba(224, 224, 224, 0.671);
        display: grid;
        grid-template-columns: 300px 1fr;
        z-index: 1;
        div.reg-rt-a1-b1-c1 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
          padding-left: 20px;
          padding-top: 23px;
          @include setFont($font-content-sub, 500, $text-lv-01);
        }
        div.reg-rt-a1-b1-c2 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
          padding-right: 20px;
          padding-top: 23px;
          @include setFont($font-content-mini, 400, $text-lv-05);
          text-align: right;
        }
      }
      //Conent : 컨텐츠 박스
      div.reg-rt-a1-b2 {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
        // padding-top: 20px;
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: 300px 1fr;
        //컬럼 1 : 프로필 이미지 업로드
        div.reg-rt-a1-b2-c1 {
          // @include debugRed();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-left: 20px;
          div.image-preview {
            // @include debugBlue();
            @include setBox(180px, 180px, 50%, 0, auto, $bg-lv-00);
            margin-top: 100px;
            // border: 3px solid #78c536;
          }
          div.profile-text-box {
            // @include debugBlue();
            @include setBox(auto, auto, 0, 0, 0, none);
            margin-top: 40px;
            text-align: center;
            p:nth-child(1) {
              @include setFont($title-font-size, 500, $text-lv-02);
            }
            p:nth-child(2) {
              @include setFont($input-font-size, 400, $text-lv-02);
              margin-top: 5px;
            }
            p:nth-child(3) {
              @include setFont($input-font-size, 400, $ywr-blue);
              margin-top: 25px;
            }
          }
        }
        //컬럼 2 : 회원가입 정보 입력
        div.reg-rt-a1-b2-c2 {
          // @include debugRed();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-top: 50px;
          display: grid;
          grid-template-rows: 1fr 150px;
          div.reg-rt-a1-b2-c2-d1 {
            // @include debugBlue();
            @include setBox(100%, 100%, 0, 0, 0, none);
            display: grid;
            grid-template-columns: 1fr 1fr;
            // 컬럼2-로우1-컬럼1 : 좌상단
            div.reg-rt-a1-b2-c2-d1-e1 {
              // @include debugRed();
              @include setBox(100%, 100%, 0, 0, 0, none);
              p {
                // @include debugBlue();
                @include setBox(auto, auto, 0, 0, 0, none);
                padding-top: 10px;
                label {
                  // @include debugBlue();
                  @include setInlineBox(130px, 28px, 0, 0, 3px, $bg-lv-06);
                  padding: 12px 0 0 10px;
                  margin-left: 30px;
                  vertical-align: middle;
                  @include setFont($title-font-size, 500, $text-lv-02);
                }
                input {
                  // @include debugBlue();
                  @include setBox(200px, $input-height, 0, 0, 0, $bg-lv-00);
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  //폰트 관련 처리
                  @include setFont($input-font-size, 400, $text-lv-02);
                }
              }
            }
            // 컬럼2-로우1-컬럼1 : 우상단
            div.reg-rt-a1-b2-c2-d1-e2 {
              // @include debugRed();
              @include setBox(100%, 100%, 0, 0, 0, none);
              p {
                // @include debugBlue();
                @include setBox(auto, auto, 0, 0, 0, none);
                padding-top: 10px;
                label {
                  // @include debugBlue();
                  @include setInlineBox(130px, 28px, 0, 0, 3px, $bg-lv-06);
                  padding: 12px 0 0 10px;
                  margin-left: 30px;
                  vertical-align: middle;
                  @include setFont($title-font-size, 500, $text-lv-02);
                }
                input {
                  // @include debugBlue();
                  @include setBox(200px, $input-height, 0, 0, 0, $bg-lv-00);
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  @include setFont($input-font-size, 400, $text-lv-02);
                }
                select {
                  // @include debugBlue();
                  @include setBox(200px, $input-height, 0, 0, 0, $bg-lv-00);
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  //폰트 관련 처리
                  @include setFont($input-font-size, 400, $text-lv-02);
                  //태그 관련 처리
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  appearance: none;
                  // background-image: url(../../image/reg-rt-option-bg.png);
                  object-fit: contain;
                }
                option {
                  //디버깅 옵션
                  // @include debugBlue();
                  //블록 관련 처리
                  @include setBox(
                    200px,
                    $input-height,
                    0,
                    0,
                    0,
                    rgba(0, 0, 0, 0)
                  );
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  //폰트 관련 처리
                  @include setFont($input-font-size, 400, $text-lv-02);
                  //태그 관련 처리
                }
              }
            }
          }
          //컬럼2-로우2 : 하단
          div.reg-rt-a1-b2-c2-d2 {
            // @include debugRed();
            @include setBox(100%, 100%, 0, 0, 0, none);
            div.reg-rt-a1-b2-c2-d2-e1 {
              // @include debugRed();
              @include setBox(auto, auto, 0, 0, 0, none);
              p {
                // @include debugRed();
                @include setBox(auto, auto, 0, 0, 0, none);
                padding-top: 10px;
                label {
                  // @include debugBlue();
                  @include setInlineBox(130px, 28px, 0, 0, 3px, $bg-lv-06);
                  padding: 12px 0 0 10px;
                  margin-left: 30px;
                  vertical-align: middle;
                  @include setFont($title-font-size, 500, $text-lv-02);
                }
                input {
                  //디버깅 옵션
                  // @include debugBlue();
                  //블록 관련 처리
                  @include setBox(430px, $input-height, 0, 0, 0, $bg-lv-00);
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  //폰트 관련 처리
                  @include setFont($input-font-size, 400, $text-lv-02);
                }
                input:nth-child(3) {
                  width: 200px;
                  margin-left: 20px;
                }
              }
            }
            div.reg-rt-a1-b2-c2-d2-e2 {
              // @include debugRed();
              @include setBox(auto, auto, 0, 0, 0, none);
              p {
                // @include debugRed();
                @include setBox(auto, auto, 0, 0, 0, none);
                padding-top: 10px;
                label:first-child {
                  // @include debugBlue();
                  @include setInlineBox(130px, 28px, 0, 0, 3px, $bg-lv-06);
                  padding: 12px 0 0 10px;
                  margin-left: 30px;
                  vertical-align: middle;
                  @include setFont($title-font-size, 500, $text-lv-02);
                }
                label {
                  // @include debugBlue();
                  @include setInlineBox(auto, auto, 0, 0, 3px, $bg-lv-06);
                  // padding: 12px 0 0 10px;
                  margin-left: 45px;
                  vertical-align: middle;
                  @include setFont($font-content-tiny, 500, $text-lv-02);
                }
                input {
                  @include setInlineBox(20px, 20px, 0, 0, 3px, $bg-lv-06);
                  vertical-align: middle;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }

    // Componant : 래귤러 박스 컴포넌트 2
    // Componant : 컴포넌트 시작
    article.reg-rt-a2 {
      // @include debugRed();
      @include setBox(1200px, 600px, 0, 0, 0, $bg-lv-06);
      margin-top: 70px;
      display: grid;
      grid-template-rows: 70px 1fr;
      // Title : 타이틀 박스
      div.reg-rt-a2-b1 {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
        box-shadow: 2px 2px 2px 2px rgba(224, 224, 224, 0.671);
        display: grid;
        grid-template-columns: 300px 1fr;
        z-index: 1;
        div.reg-rt-a2-b1-c1 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
          padding-left: 20px;
          padding-top: 23px;
          @include setFont($font-content-sub, 500, $text-lv-01);
        }
        div.reg-rt-a2-b1-c2 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
          padding-right: 20px;
          padding-top: 23px;
          @include setFont($font-content-mini, 400, $text-lv-05);
          text-align: right;
        }
      }
      div.reg-rt-a2-b2 {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
        padding-bottom: 20px;
        padding: 55px;
        div.reg-rt-a2-b2-c1 {
          //디버깅 옵션
          // @include debugGreen();
          //블록 관련 처리
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          div {
            //디버깅 옵션
            // @include debugBlue();
            //블록 관련 처리
            @include setBox(auto, 30px, 0, 0, auto, $bg-lv-06);
            padding-top: 10px;
            //폰트 관련 처리
            @include setFont($title-font-size, 500, $text-lv-02);
            vertical-align: middle;
          }
        }
        div.reg-rt-a2-b2-c2 {
          //디버깅 옵션
          // @include debugBlue();
          margin-top: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          p {
            //디버깅 옵션
            // @include debugRed();
            padding-left: 25px;
            input {
              //디버깅 옵션
              @include debugBlue();
              //블록 관련 처리
              @include setBox(100%, $input-height, 0, 0, 0, $bg-lv-06);
              padding: 0 0 0 10px;
              display: inline-block;
              vertical-align: middle;
              border: 1px solid gainsboro;
              border-radius: 5px;
              //폰트 관련 처리
              @include setFont($input-font-size, 400, $text-lv-02);
            }
            select {
              //디버깅 옵션
              // @include debugBlue();
              //블록 관련 처리
              @include setBox(100%, $input-height, 0, 0, 0, $bg-lv-00);
              padding: 0 0 0 10px;
              display: inline-block;
              vertical-align: middle;
              border: 1px solid gainsboro;
              border-radius: 5px;
              //폰트 관련 처리
              @include setFont($input-font-size, 400, $text-lv-02);
              //태그 관련 처리
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              // background-image: url(../../image/reg-rt-option-bg.png);
              object-fit: contain;
            }
          }
        }
      }
    }

    button {
      //디버깅 옵션
      // @include debugBlue();
      //블록 관련 처리
      @include setBox(20vw, $input-height, 0, 0, auto, $text-lv-03);
      padding: 0 0 0 10px;
      margin-top: 30px;
      margin-bottom: 100px;
      vertical-align: middle;
      border: 1px solid gainsboro;
      border-radius: 5px;
      //폰트 관련 처리
      @include setFont($input-font-size, 400, $text-lv-06);
    }
  }
}
span.haveto {
  color: $ywr-red;
}
</style>
