<template>
  <div id="app">
    <TopNav />
    <section class="main-container">
      <router-view></router-view>
    </section>
    <Footer />
  </div>
</template>

<script>
import TopNav from './components/TopNav.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    TopNav,
    Footer,
  },
};
</script>

<style lang="scss">
@import '@/design/init.scss';
@import '@/design/_variables.scss';
@import '@/design/_mixins.scss';
@import '@/design/_functions.scss';
div#app {
  // @include debugRed();
  @include setBox(100vw, auto, 0, 0, 0, $bg-lv-00);
  section.main-container {
    // @include debugBlue();
    @include setBox(100%, auto, 0, 0, 0, none);
    padding-top: 140px;
  }
}
</style>
