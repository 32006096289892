<template>
  <section class="scholarships-container-box">Scholarships</section>
</template>

<script>
export default {
  name: 'Scholarships',
};
</script>

<style lang="scss">
@import '../design/init.scss';
@import '../design/_variables.scss';
@import '../design/_mixins.scss';
@import '../design/_functions.scss';

section.scholarships-container-box {
}
</style>
