import '@babel/polyfill';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

let app = createApp(App);
app.use(store);
app.use(VueSweetalert2);
app.use(router);
app.mount('#app');
