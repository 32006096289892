import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state: {
    // 로그인

    isLogin: false,
    loginUserId: '',
    isLoginError: false,
    // 탑 네비게인션
    topNavStats: 'main',
    footerStats: 'main',
    regUserType: 'RT',
    // 탑 네비게인션 : Sub
    topNavKoTitle: '한글제목',
    topNavEnTitle: 'ENGLISH TITLE',
    // 지원 스텝 변수
  },
  mutations: {
    // Login
    // 로그인 성공
    loginSuccess(state) {
      state.isLogin = true;
      state.isLoginError = false;
    },
    // 로그인 실패
    loginError(state) {
      state.isLogin = false;
      state.isLoginError = true;
    },

    // TopNav
    chaingeTopNavMain(state) {
      state.topNavStats = 'main';
    },
    chaingeTopNavSub(state) {
      state.topNavStats = 'sub';
    },
    chaingeTopNavLogin(state) {
      state.topNavStats = 'login';
    },
    chaingeTopNavNone(state) {
      state.topNavStats = 'none';
    },
    // TopNav Sub
    chaingeSubTitleSignUp(state) {
      state.topNavKoTitle = ' 회원가입';
      state.topNavEnTitle = 'SignUp';
    },
    chaingeSubTitleSignUpRt(state) {
      state.topNavKoTitle = '렘넌트 회원가입';
      state.topNavEnTitle = 'SignUp For Remnant';
    },
    chaingeSubTitleSignUpMI(state) {
      state.topNavKoTitle = '교역자 회원가입';
      state.topNavEnTitle = 'SignUp For MINISTER';
    },
    chaingeSubTitleSignUpTH(state) {
      state.topNavKoTitle = '교사 회원가입';
      state.topNavEnTitle = 'SignUp For TEACHER';
    },
    chaingeSubTitleSignUpPR(state) {
      state.topNavKoTitle = '전문산업인 회원가입';
      state.topNavEnTitle = 'SignUp For PROFESSIONAL';
    },
    chaingeSubTitleSignUpOF(state) {
      state.topNavKoTitle = '사역자  회원가입';
      state.topNavEnTitle = 'SignUp For OFFICER';
    },
    // Footer
    chaingeFooterMain(state) {
      state.footerStats = 'main';
    },
    chaingeFooterNone(state) {
      state.footerStats = 'none';
    },
    // Sign Up
    regUserTyepRT(state) {
      state.regUserType = 'RT';
    },
    regUserTyepMI(state) {
      state.regUserType = 'MI';
    },
    regUserTyepTH(state) {
      state.regUserType = 'TH';
    },
    regUserTyepPR(state) {
      state.regUserType = 'PR';
    },
    regUserTyepOF(state) {
      state.regUserType = 'OF';
    },
  },
  actions: {
    // 로그인 시도
    login(a) {
      // [state, commit],
      // 전체 유저에서 해당 Email로 유저를 찾는다
      console.log('?!');
      console.log(a);
      // let selectedUser = null;
      // state.allUsers.forEach((user) => {
      //   if (user.email === loginObject.email) selectedUser = user;
      // });
      // selectedUser === null
      //   ? commit('loginError')
      //   : selectedUser.password !== loginObject.password
      //   ? commit('loginError')
      //   : commit('loginSuccess');
    },
    //데이터 가져오기
    getData() {
      axios
        .get('https://www.yewon-remnant.net/api/organizations')
        .then((result) => {
          console.log(result);
        });
    },
  },
});

export default store;
