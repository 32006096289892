import { createWebHistory, createRouter } from 'vue-router';
import Home from '../components/Home';
import AboutUs from '../components/AboutUs';
import Scholarships from '../components/Scholarships';
import UserType from '../components/UserType';
import SignUp from '../components/SignUp';
import Login from '../components/Login';
import Apply from '../components/Apply';
import Test from '../components/Test';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/Scholarships',
    name: 'Scholarships',
    component: Scholarships,
  },
  {
    path: '/UserType',
    name: 'UserType',
    component: UserType,
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Apply',
    name: 'Apply',
    component: Apply,
  },
  {
    path: '/Test',
    name: 'Test',
    component: Test,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
