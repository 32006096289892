<template>
  <header v-if="topNavStats == 'main'" class="top-navication">
    <nav>
      <router-link to="/">
        <img src="../assets/images/top_nav_logo.png" alt="" />
      </router-link>
      <router-link to="">
        <a href="">
          <!-- <h2>About us</h2>
          <h3>About us</h3> -->
        </a>
      </router-link>
      <router-link to="">
        <a href="">
          <!-- <h2>장학금 소개</h2>
          <h3>Scholarshipsu</h3> -->
        </a>
      </router-link>
      <router-link
        to="/UserType"
        @click="
          [chaingeTopNavSub(), chaingeFooterMain(), chaingeSubTitleSignUp()]
        "
      >
        <a href="">
          <h2>회원가입</h2>
          <h3>Sign up</h3>
        </a>
      </router-link>
      <router-link
        to="/Login"
        @click="[chaingeTopNavNone(), chaingeFooterNone()]"
      >
        <a href="">
          <h2>Login</h2>
          <h3>Login</h3>
        </a>
      </router-link>
    </nav>
  </header>

  <header v-if="topNavStats == 'sub'" class="top-navication-sub">
    <nav>
      <header>
        <img src="../assets/images/top_nav_sub_arrow@2x.png" />
        <hgroup>
          <h1>{{ topNavKoTitle }}</h1>
          <h2>{{ topNavEnTitle }}</h2>
        </hgroup>
      </header>
      <a href=""> </a>
      <a href=""> </a>
      <a href=""> </a>
      <a href=""> </a>
    </nav>
  </header>

  <header v-if="topNavStats == 'login'" class="top-navication-login">
    <nav>
      <router-link to="/">
        <img src="../assets/images/top_nav_logo.png" alt="" />
      </router-link>
      <router-link to="/AboutUs">
        <a href=""> </a>
      </router-link>
      <router-link to="/Scholarships">
        <a href=""> </a>
      </router-link>
      <router-link to="/UserType">
        <a href=""> </a>
      </router-link>
      <router-link to="">
        <a class="dropdown-box" href="">
          <div class="dropdown">
            <div>
              <img src="../assets/images/user_default_icon@2x.png" alt="" />
              <h3>
                이용걸<br />
                <span>Remnant</span>
              </h3>
            </div>

            <div class="dropdown-content">
              <p>
                장학금 신청하기<br />
                <span>Apply for a scholarship</span>
              </p>
              <p>
                마이페이지<br />
                <span>My Page</span>
              </p>
              <p>
                로그아웃<br />
                <span>Log out</span>
              </p>
            </div>
          </div>
        </a>
      </router-link>
    </nav>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'TopNav',
  props: {},
  computed: {
    ...mapState([
      // 텝 네비게이션 상태변수
      'topNavStats',
      // 유저타입 상태변수
      'regUserType',
      //탑 네비게이션 : Sub 변수
      'topNavKoTitle',
      'topNavEnTitle',
    ]),
  },
  methods: {
    ...mapMutations([
      // 변경함수 : 탑 네비게이션 상태변수
      'chaingeTopNavMain',
      'chaingeTopNavSub',
      'chaingeTopNavLogin',
      'chaingeTopNavNone',
      // 변경함수 : 탑 네비게이션 서브 타이틀 변경
      'chaingeSubTitleSignUp',
      'chaingeSubTitleSignUpRt',
      // 변경함수 : 푸터 상태 변경
      'chaingeFooterMain',
      'chaingeFooterNone',
      // 변경함수 : 유저타입 상태변수
      'regUserTyepRT',
      'regUserTyepMI',
      'regUserTyepTH',
      'regUserTyepPR',
      'regUserTyepOF',
    ]),
  },
};
</script>

<style lang="scss">
@import '../design/init.scss';
@import '../design/_variables.scss';
@import '../design/_mixins.scss';
@import '../design/_functions.scss';
// Stats Main
header.top-navication {
  // @include debugRed();
  @include setBox(100%, 140px, 0, 0, 0, $bg-lv-06);
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.281);
  margin-bottom: 30px;
  position: fixed;
  z-index: 10;
  nav {
    // @include debugRed();
    @include setBox(1300px, 100%, 0, 0, auto, $bg-lv-06);
    display: grid;
    grid-template-columns: 800px 1fr 1fr 1fr 1fr;
    img {
      // @include debugRed();
      @include setBox(auto, auto, 0, 0, 0, none);
      margin-top: 37px;
    }
    a {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 0, 0, none);
      text-align: center;
      text-decoration: none;
      h2 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        margin-top: 48px;
        @include setFont($font-content-basic, 00, $text-lv-02);
      }
      h3 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        @include setFont($font-content-mini, 400, $text-lv-04);
        margin-top: 5px;
      }
    }
  }
}

// Stats Sub
header.top-navication-sub {
  // @include debugRed();
  @include setBox(100%, 140px, 0, 0, 0, $bg-lv-06);
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.281);
  margin-bottom: 30px;
  position: fixed;
  z-index: 10;
  nav {
    // @include debugRed();
    @include setBox(1300px, 100%, 0, 0, auto, $bg-lv-06);
    display: grid;
    grid-template-columns: 800px 1fr 1fr 1fr 1fr;
    header {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 0, 0, none);
      display: grid;
      grid-template-columns: 30px 1fr;
      img {
        // @include debugRed();
        @include setBox(30px, 50px, 0, 0, 0, none);
        margin-top: 43px;
      }
      hgroup {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        margin-top: 49px;
        text-align: left;
        padding-left: 20px;
        h1 {
          // @include debugRed();
          @include setBox(auto, auto, 0, 0, 0, none);
          @include setFont($font-content-sub, 500, $text-lv-01);
        }
        h2 {
          // @include debugRed();
          @include setBox(auto, auto, 0, 0, 0, none);
          @include setFont($font-content-sub, 400, $text-lv-04);
          margin-top: 3px;
        }
      }
    }

    a {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 0, 0, none);
      text-align: center;
      text-decoration: none;
      h2 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        margin-top: 48px;
        @include setFont($font-content-basic, 00, $text-lv-02);
      }
      h3 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        @include setFont($font-content-mini, 400, $text-lv-04);
        margin-top: 5px;
      }
    }
  }
}

// Stats Login
// Stats Main
header.top-navication-login {
  // @include debugRed();
  @include setBox(100%, 140px, 0, 0, 0, $bg-lv-06);
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.281);
  margin-bottom: 30px;
  position: fixed;
  z-index: 10;
  nav {
    // @include debugRed();
    @include setBox(1300px, 100%, 0, 0, auto, $bg-lv-06);
    display: grid;
    grid-template-columns: 800px 1fr 1fr 1fr 220px;
    img {
      // @include debugRed();
      @include setBox(auto, auto, 0, 0, 0, none);
      margin-top: 37px;
    }
    a {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 0, 0, none);
      text-align: center;
      text-decoration: none;
      h2 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        margin-top: 48px;
        @include setFont($font-content-basic, 00, $text-lv-02);
      }
      h3 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        @include setFont($font-content-mini, 400, $text-lv-04);
        margin-top: 5px;
      }
      a.dropdown-box {
        // @include debugBlue();
        @include setBox(100%, 60px, 0, 0, 0, none);
        margin-top: 35px;
        div {
          // @include debugRed();
          @include setBox(auto, auto, 0, 0, 0, none);
          display: grid;
          grid-template-columns: 50px 1fr;
          img {
            // @include debugRed();
            @include setBox(50px, 50px, 50%, 0, 0, none);
            border: 2px solid $ywr-green;
          }

          h3 {
            // @include debugGreen();
            @include setBox(auto, auto, 0, 0, 0, none);
            @include setFont($font-content-basic, 500, $text-lv-02);
            text-align: left;
            padding-top: 10px;
            margin-left: 20px;
            span {
              @include setFont($font-content-basic, 400, $text-lv-04);
            }
          }
        }
        .dropdown {
          position: relative;
          display: inline-block;
        }

        div.dropdown-content {
          // @include debugGreen();
          @include setBox(180px, auto, 15px, 0, 0, none);
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          background-color: #f9f9f9;
          min-width: 160px;
          margin-left: -20px;
          margin-top: 0;
          padding: 12px 25px;
          display: none;
          position: absolute;
          z-index: 1;
          p {
            // @include debugGreen();
            @include setBox(100%, 60px, 0, 0, 0, none);
            @include setFont($font-content-basic, 400, $text-lv-02);
            text-align: left;
            line-height: 30px;
            span {
              // @include debugGreen();
              @include setBox(100%, 30px, 0, 0, 0, none);
              @include setFont($font-content-tiny, 400, $text-lv-05);
              line-height: 14px;
            }
          }
          p:first-child {
            margin-top: 50px;
          }
          p:last-child {
            margin-bottom: 20px;
          }
        }

        .dropdown:hover .dropdown-content {
          display: block;
        }
      }
    }
  }
}
</style>
