<template>
  <footer v-if="footerStats == 'main'" class="main-footer">
    <section>
      <hgroup>
        <img src="../assets/images/logo_small.png" alt="" />
        <h4>예원장학국</h4>
        <h5>Yewon Scholarship Bureau</h5>
        <h4>예원 렘넌트 인턴쉽</h4>
        <h5>Yewon Remnant Internship</h5>
      </hgroup>
      <hgroup>
        <div>
          <img src="../assets/images/footer_icon_facebook.png" alt="" />
          <img src="../assets/images/footer_icon_instargram.png" alt="" />
          <img src="../assets/images/footer_icon_youtube.png" alt="" />
        </div>
        <h5>서울시 강서구 화곡로63길 65, 2층(등촌동, 예원교회)</h5>
        <h5>대표전화 02-2657-9770 | 팩스 02-2657-9769</h5>
        <h5>진행문의 010-3383-4177</h5>
        <h6>Copyright @ 2021 yewonremnant.com. All Reights Reservied</h6>
      </hgroup>
    </section>
  </footer>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'Footer',
  computed: {
    ...mapState(['footerStats']),
  },
  methods: {
    ...mapMutations(['chaingeFooterMain', 'chaingeFooterNone']),
  },
};
</script>

<style lang="scss">
@import '../design/init.scss';
@import '../design/_variables.scss';
@import '../design/_mixins.scss';
@import '../design/_functions.scss';

footer.main-footer {
  // @include debugRed();
  @include setBox(100%, 320px, 0, 0, auto, $bg-lv-06);
  padding-top: 20px;
  section {
    // @include debugRed();
    @include setBox(1300px, 100%, 0, 0, auto, $bg-lv-06);
    display: grid;
    grid-template-columns: 1fr 1fr;
    // padding-left: 140px;
    // padding-right: 140px;
    hgroup {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 0, 0, none);
    }
    hgroup:nth-child(1) {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 0, 0, none);
      img {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        margin-top: 40px;
      }
      h4 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        @include setFont($font-content-basic, 500, $text-lv-02);
        margin-top: 15px;
      }
      h5 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        @include setFont($font-content-mini, 400, $text-lv-04);
        margin-top: 5px;
      }
    }
    hgroup:nth-child(2) {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 0, 0, none);
      text-align: right;
      div {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        margin-top: 100px;
        margin-bottom: 30px;
        img {
          margin-left: 5px;
        }
      }
      h5 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        @include setFont($font-content-mini, 400, $text-lv-03);
        margin-top: 8px;
      }
      h6 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, none);
        @include setFont($font-content-tiny, 400, $text-lv-04);
        margin-top: 13.5px;
      }
    }
  }
}
</style>
