<template>
  <div class="usertype-container-box">
    <section class="usertype-content">
      <article>
        <header>
          <img src="../assets/images/user_type_rt@2x.png" alt="" />
          <h3>예원렘넌트</h3>
          <h4>REMNANT</h4>
        </header>
        <p>
          21세기의 주역! 교회와 현장, 시대를 살릴 예원의 렘넌트들은 여기서
          가입해 주세요
        </p>
        <router-link style="text-decoration: none" to="/SignUp">
          <button
            @click="
              [chaingeTopNavSub(), chaingeSubTitleSignUpRt(), regUserTyepRT()]
            "
          >
            렘넌트 회원가입
          </button>
        </router-link>
      </article>
      <article>
        <header>
          <img src="../assets/images/user_type_mi@2x.png" alt="" />
          <h3>교역자</h3>
          <h4>MINISTER</h4>
        </header>
        <p>각 기관에 담당 교역자 분들은 여기서 가입해 주세요</p>
        <router-link style="text-decoration: none" to="/SignUp">
          <button
            disabled
            @click="
              [chaingeTopNavSub(), chaingeSubTitleSignUpMI(), regUserTyepMI()]
            "
          >
            교역자 회원가입
          </button>
        </router-link>
      </article>
      <article>
        <header>
          <img src="../assets/images/user_type_th@2x.png" alt="" />
          <h3>기관교사</h3>
          <h4>TEACHER</h4>
        </header>
        <p>각 기관에 헌신해주시는 교사 분들은 여기서 가입해 주시면 됩니다</p>
        <router-link style="text-decoration: none" to="/SignUp">
          <button
            disabled
            @click="
              [chaingeTopNavSub(), chaingeSubTitleSignUpTH(), regUserTyepTH()]
            "
          >
            교사 회원가입
          </button>
        </router-link>
      </article>
      <article>
        <header>
          <img src="../assets/images/user_type_ex@2x.png" alt="" />
          <h3>전문산업인</h3>
          <h4>PROFESSIONAL</h4>
        </header>
        <p>인턴쉽 기록을 위해 전문가들은 여기서 가입해 주세요</p>
        <router-link style="text-decoration: none" to="/SignUp">
          <button
            disabled
            @click="
              [chaingeTopNavSub(), chaingeSubTitleSignUpPR(), regUserTyepPR()]
            "
          >
            전문인 회원가입
          </button>
        </router-link>
      </article>
      <article>
        <header>
          <img src="../assets/images/user_type_of@2x.png" alt="" />
          <h3>장학 & 인턴쉽 사역자</h3>
          <h4>OFFICER</h4>
        </header>
        <p>장학금 및 인턴쉽 사역을 위한 사역자들은 이 곳에서 가입해주세요</p>
        <router-link style="text-decoration: none" to="/SignUp">
          <button
            disabled
            @click="
              [chaingeTopNavSub(), chaingeSubTitleSignUpOF(), regUserTyepOF()]
            "
          >
            사역자 회원가입
          </button>
        </router-link>
      </article>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'UserType',
  computed: {
    ...mapState([
      // 텝 네비게이션 상태변수
      'topNavStats',
      // 유저타입 상태변수
      'regUserType',
      //탑 네비게이션 : Sub 변수
      'topNavKoTitle',
      'topNavEnTitle',
    ]),
  },
  methods: {
    ...mapMutations([
      // 변경함수 : 탑 네비게이션 상태변수
      'chaingeTopNavMain',
      'chaingeTopNavSub',
      'chaingeTopNavLogin',
      'chaingeTopNavNone',
      // 변경함수 : 탑 네비게이션 서브 타이틀 변경
      'chaingeSubTitleSignUp',
      'chaingeSubTitleSignUpRt',
      'chaingeSubTitleSignUpMI',
      'chaingeSubTitleSignUpTH',
      'chaingeSubTitleSignUpPR',
      'chaingeSubTitleSignUpOF',
      // 변경함수 : 푸터 상태 변경
      'chaingeFooterMain',
      'chaingeFooterNone',
      // 변경함수 : 유저타입 상태변수
      'regUserTyepRT',
      'regUserTyepMI',
      'regUserTyepTH',
      'regUserTyepPR',
      'regUserTyepOF',
    ]),
  },
  mounted() {
    this.chaingeTopNavSub;
    this.chaingeSubTitleSignUpRt;
  },
};
</script>

<style lang="scss">
@import '../design/_variables.scss';
@import '../design/init.scss';
@import '../design/_mixins.scss';
@import '../design/_functions.scss';

div.usertype-container-box {
  // @include debugRed();
  @include setBox(100%, auto, 0, 0, auto, $bg-lv-00);
  padding-top: 50px;
  padding-bottom: 50px;
  section {
    // @include debugRed();
    @include setBox(1300px, 500px, 0, 0, auto, $bg-lv-00);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    article {
      // @include debugRed();
      @include setBox(100%, 100%, 5px, 0, auto, $bg-lv-06);
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.137);
      header {
        // @include debugRed();
        @include setBox(100%, auto, 0, 0, auto, $bg-lv-06);
        img {
          // @include debugRed();
          @include setBox(148px, 148px, 50%, 0, auto, $bg-lv-06);
          margin-top: 50px;
        }
        h3 {
          // @include debugRed();
          @include setBox(auto, auto, 0, 0, auto, $bg-lv-06);
          margin-top: 40px;
          @include setFont($font-content-basic, 600, $text-lv-02);
          text-align: center;
        }
        h4 {
          // @include debugRed();
          @include setBox(auto, auto, 0, 0, auto, $bg-lv-06);
          margin-top: 5px;
          @include setFont($font-content-basic, 400, $text-lv-04);
          text-align: center;
        }
      }
      p {
        // @include debugRed();
        @include setBox(170px, 70px, 2px, 0, auto, none);
        margin-top: 21px;
        @include setFont($font-content-mini, 400, $text-lv-03);
        line-height: 20px;
        text-align: center;
      }
      button {
        // @include debugRed();
        @include setBox(140px, 40px, 5px, 0, auto, none);
        margin-top: 25px;
        @include setFont($font-content-mini, 500, $text-lv-01);
        @include greenButton();
      }
      button:active {
        @include greenButtonActive();
      }
    }
  }
}
</style>
