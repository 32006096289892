<template>
  <div class="apply-container-box">
    <section class="apply-content">
      <!-- Componant : 컴포넌트 시작 -->
      <article v-if="applyStep == 10" class="apply-defalt-from">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <h2>일반정보</h2>
          <h3>General information</h3>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section></section>
      </article>

      <!-- Apply Step : 0 -->
      <!-- Apply Step : 0-1 -->
      <article v-if="applyStep == 0" class="apply-step-0-1">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <img src="../assets/images/apply_logo@2x.png" alt="" />
          <h2>장학금 신청서</h2>
          <h3>Scholarship Application</h3>
          <img class="tempImage" src="../assets/images/apply_hero.png" alt="" />
          <p class="apply-select-box">
            <select v-model="applicationType" id="f3-rel" name="f3-rel">
              <option value="">지원하실 장학금 유형을 선택하세요</option>
              <option value="A">비전/차세대/드림/237 (동일양식)</option>
              <option value="B">미션&인턴쉽</option>
            </select>
          </p>
        </hgroup>
      </article>

      <router-link v-if="applyStep == 0" style="text-decoration: none" to="">
        <button @click="checkSelectApplyType">
          예원렘넌트장학금에 지원합니다
        </button>
      </router-link>

      <!-- Apply Step : 1 -->
      <!-- Apply Step : 1-1 -->
      <article v-if="applyStep == 1" class="apply-step-1-1">
        <hgroup>
          <h2>CVDIP</h2>
          <h3>하나님이 나에게 허락하신 CVDIP를 적어주세요</h3>
        </hgroup>
      </article>

      <!-- Apply Step : 1-2 -->
      <article v-if="applyStep == 1" class="apply-step-1-2">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <h2>Covenant</h2>
          <h3>하나님께서 나에게 주신 언약은 무엇인가요?</h3>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <textarea
            @keyup="countC"
            v-model="messageC"
            placeholder="하나님이 나에게 주신 언약은 무엇인가요?"
          ></textarea>
          <span :class="themC" id="apply-c-count">{{ messageLengthC }}</span>
        </section>
      </article>

      <!-- Apply Step : 1-2 -->
      <article v-if="applyStep == 1" class="apply-step-1-2">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <h2>Vision</h2>
          <h3>하나님께서 나에게 주신 비전은 무엇인가요?</h3>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <textarea
            @keyup="countV"
            v-model="messageV"
            placeholder="하나님께서 나에게 주신 비전은 무엇인가요?"
          ></textarea>
          <span :class="themV" id="apply-c-count">{{ messageLengthV }}</span>
        </section>
      </article>

      <!-- Apply Step : 1-2 -->
      <article v-if="applyStep == 1" class="apply-step-1-2">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <h2>Dream</h2>
          <h3>하나님께서 나에게 주신 꿈은 무엇인가요?</h3>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <textarea
            @keyup="countD"
            v-model="messageD"
            placeholder="하나님이 나에게 주신 꿈은 무엇인가요?"
          ></textarea>
          <span :class="themD" id="apply-c-count">{{ messageLengthD }}</span>
        </section>
      </article>

      <!-- Apply Step : 1-2 -->
      <article v-if="applyStep == 1" class="apply-step-1-2">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <h2>Image</h2>
          <h3>General information</h3>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <textarea
            @keyup="countI"
            v-model="messageI"
            placeholder="하나님이 나에게 주신 이미지는 무엇인가요?"
          ></textarea>
          <span :class="themI" id="apply-c-count">{{ messageLengthI }}</span>
        </section>
      </article>

      <!-- Apply Step : 1-2 -->
      <article v-if="applyStep == 1" class="apply-step-1-2">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <h2>Practice</h2>
          <h3>구체적으로 어떤 프랙티스들을 하고 있나요?</h3>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <textarea
            @keyup="countP"
            v-model="messageP"
            placeholder="하나님이 나에게 주신 언약은 무엇인가요?"
          ></textarea>
          <span :class="themP" id="apply-c-count">{{ messageLengthP }}</span>
        </section>
      </article>

      <!-- Apply Step : 1-3 -->
      <article v-if="applyStep == 1" class="apply-step-1-3">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <div>
            <h2>증빙서류</h2>
            <p>위의 내용을 증명하기 위한 서류들을 이곳에 업로드합니다.</p>
          </div>
          <a href="https://forms.gle/W6ZmSr5s7YbaKz7b6" target="_blank">
            <button @click="checkSelectApplyStapA">파일 업로드</button></a
          >
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <hgroup v-if="applyStep == 10" class="apply-no-file">
            <h5>아직 업로드된 서류들이 없습니다</h5>
            <h6>위의 내용을 증명하기 위한 서류들을 이곳에 업로드합니다.</h6>
          </hgroup>
        </section>
      </article>
      <router-link v-if="applyStep == 1" style="text-decoration: none" to="">
        <button @click="checkSelectApplyStapA">
          예원렘넌트장학금에 지원합니다
        </button>
      </router-link>

      <!-- Apply Step : 2 -->
      <!-- Apply Step : 2-1 -->
      <article v-if="applyStep == 2" class="apply-step-1-1">
        <hgroup>
          <h2>미션 & 인턴쉽 장학금</h2>
          <h3>미션 & 인턴쉽 장학금</h3>
        </hgroup>
      </article>

      <!-- Apply Step : 2-2 -->
      <article v-if="applyStep == 2" class="apply-step-1-2">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <h2>특기 관련 분야(또는 2021년 인턴십 분야)</h2>
          <h3>특기 관련분야 또는 2021년 인턴십 분야를 입력하세요</h3>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <textarea
            @keyup="countMI1"
            v-model="messageMI1"
            placeholder=" 예시) 야구, 미용, 피아노, 미술(조소), 게임, 유튜브 편집, 무용, 파워 블로거, 제2외국어 능력, 발명 또는 특허 보유, 요리, 웹툰 연재, 방송댄스, 기타 악기 연주, 프로그램 코딩 등 "
          ></textarea>
          <span :class="themMI1" id="apply-c-count">{{
            messageLengthMI1
          }}</span>
        </section>
      </article>

      <!-- Apply Step : 1-2 -->
      <article v-if="applyStep == 2" class="apply-step-1-2">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <h2>특기 세부 내용 or 인턴십 분야에 대해 느낀점</h2>
          <h3>특정포지션, 특기 시작 시기 등 또는 인턴십 분야에 대해 느낀점</h3>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <textarea
            @keyup="countMI2"
            v-model="messageMI2"
            placeholder="예시1) 야구, 축구 등 단체구기종목과 같이 특정 포지션이 존재하는 분야는 세부적인 내용을 기재해 주시기 바랍니다. 
            예시2) 초등학교 3학년때부터 제과 제빵분야에 관심이 생기면서 시작해서 제과제빵분야 자격증을 취득했습니다. ... 
"
          ></textarea>
          <span :class="themMI2" id="apply-c-count">{{
            messageLengthMI2
          }}</span>
        </section>
      </article>

      <!-- Apply Step : 1-2 -->
      <article v-if="applyStep == 2" class="apply-step-1-2">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <h2>특기 성과 (인턴쉽 발전방향)</h2>
          <h3>아래의 내용을 입력하세요</h3>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <textarea
            @keyup="countMI3"
            v-model="messageMI3"
            placeholder="(인턴십 장학생 지원자의 경우 인턴십을 통해서 ‘향후 목표’ 또는 ‘발전해보고자 하는 방향’에 대해서 기술 하시오)

예시1) 
OO피아노 콩쿠르대회(2020.4.16) 우수상

예시2) 한식조리기능사(2019.12.15) 한국산업인력공단

예시3) 제87회 전국한자능력검정시험 공인자격 1급(2019.7)
토플 (2020.10)ETS 84점, 

예시4) 여행관련 블로그 운영(회원수 OOOO명)
음악관련 유튜브(조회수 OOOOOO회, 구독자 OOOO명) 

*수상이력(최근 2년이내) 및 기타성과 최대 5개까지 입력가능
"
          ></textarea>
          <span :class="themMI3" id="apply-c-count">{{
            messageLengthMI3
          }}</span>
        </section>
      </article>

      <!-- Apply Step : 1-3 -->
      <article v-if="applyStep == 2" class="apply-step-1-3">
        <!-- Title : 타이틀 박스 -->
        <hgroup>
          <div>
            <h2>증빙서류</h2>
            <p>위의 내용을 증명하기 위한 서류들을 이곳에 업로드합니다.</p>
          </div>
          <a href="https://forms.gle/FzKPeawpnpP5wW3i8" target="_blank">
            <button>파일 업로드</button>
          </a>
        </hgroup>
        <!-- Conent : 컨텐츠 박스 -->
        <section>
          <hgroup v-if="applyStep == 10" class="apply-no-file">
            <h5>아직 업로드된 서류들이 없습니다</h5>
            <h6>위의 내용을 증명하기 위한 서류들을 이곳에 업로드합니다.</h6>
          </hgroup>
        </section>
      </article>
      <router-link v-if="applyStep == 2" style="text-decoration: none" to="">
        <button @click="checkSelectApplyStapB">지원합니다</button>
      </router-link>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import axios from 'axios';
export default {
  name: 'SignUp',
  data() {
    return {
      //
      applyStep: 0,
      // 유저정보
      regInfo: {
        // user table
        name: '',
        email: '',
        password: '',
        passwordCheck: '',
        phoneNum: '',
        birth_date: '',
        gender: '',
        address: '',
        address_detail: '',
        user_type: 'RT',
        organization_id: '',
        church_office_id: '',
        internship_field_id: '',
        // school table
        schoolName: '',
        grade: '',
        location: 'n',
        // traning table
        training: [],
      },
      // 장학금 지원 유형
      applicationType: '',
      // 글자 길이체크
      minLength: 1,
      // 클래스제어 : C
      messageLengthC: 0,
      messageC: '',
      themC: '',
      // 클래스제어 : V
      messageLengthV: 0,
      messageV: '',
      themV: '',
      // 클래스제어 : C
      messageLengthD: 0,
      messageD: '',
      themD: '',
      // 클래스제어 : C
      messageLengthI: 0,
      messageI: '',
      themI: '',
      // 클래스제어 : C
      messageLengthP: 0,
      messageP: '',
      themP: '',
      // 클래스제어 : A
      messageLengthMI1: 0,
      messageMI1: '',
      themMI1: '',
      // 클래스제어 : B
      messageLengthMI2: 0,
      messageMI2: '',
      themMI2: '',
      // 클래스제어 : C
      messageLengthMI3: 0,
      messageMI3: '',
      themMI3: '',
    };
  },
  computed: {
    ...mapState([
      // 텝 네비게이션 상태변수
      'topNavStats',
      // 유저타입 상태변수
      'regUserType',
      //탑 네비게이션 : Sub 변수
      'topNavKoTitle',
      'topNavEnTitle',
      //지원스텝 상태변수
      'applyStep',
    ]),
  },
  watch: {
    messageLengthC(value) {
      if (value >= 1) {
        this.themC = 'enoughLength';
      } else {
        this.themC = 'notEnoughLength';
      }
    },
    messageLengthV(value) {
      if (value >= 1) {
        this.themV = 'enoughLength';
      } else {
        this.themV = 'notEnoughLength';
      }
    },
    messageLengthD(value) {
      if (value >= 1) {
        this.themD = 'enoughLength';
      } else {
        this.themD = 'notEnoughLength';
      }
    },
    messageLengthI(value) {
      if (value >= 1) {
        this.themI = 'enoughLength';
      } else {
        this.themI = 'notEnoughLength';
      }
    },
    messageLengthP(value) {
      if (value >= 1) {
        this.themP = 'enoughLength';
      } else {
        this.themP = 'notEnoughLength';
      }
    },
    messageLengthMI1(value) {
      if (value >= 1) {
        this.themMI1 = 'enoughLength';
      } else {
        this.themMI1 = 'notEnoughLength';
      }
    },
    messageLengthMI2(value) {
      if (value >= 1) {
        this.themMI2 = 'enoughLength';
      } else {
        this.themMI2 = 'notEnoughLength';
      }
    },
    messageLengthMI3(value) {
      if (value >= 1) {
        this.themMI3 = 'enoughLength';
      } else {
        this.themMI3 = 'notEnoughLength';
      }
    },
  },
  methods: {
    ...mapMutations([
      // 변경함수 : 탑 네비게이션 상태변수
      'chaingeTopNavMain',
      'chaingeTopNavSub',
      'chaingeTopNavLogin',
      'chaingeTopNavNone',
      // 변경함수 : 탑 네비게이션 상태변수
      'chaingeFooterMain',
      'chaingeFooterNone',
      // 변경함수 : 유저타입 상태변수
      'regUserTyepRT',
      'regUserTyepMI',
      'regUserTyepTH',
      'regUserTyepPR',
      'regUserTyepOF',
      // 변경함수 : 지원스텝
      'addApplyStep',
      'minusApplyStep',
      'resetApplyStep',
    ]),
    // 분량 미충족
    showAlert() {
      // Use sweetalert2
      this.$swal({
        icon: 'error',
        title: '분량 미충족',
        text: '각 항목을 정해진 분량 이상 작성해 주세요',
      });
    },
    finishApply() {
      // Use sweetalert2
      this.$swal({
        icon: 'success',
        title: '지원서 제출 완료',
        text: '제출이 성공적으로 완료되었습니다. (로그아웃됨)',
      });
    },
    countC() {
      this.messageLengthC = this.messageC.length;
    },
    countV() {
      this.messageLengthV = this.messageV.length;
    },
    countD() {
      this.messageLengthD = this.messageD.length;
    },
    countI() {
      this.messageLengthI = this.messageI.length;
    },
    countP() {
      this.messageLengthP = this.messageP.length;
    },
    countMI1() {
      this.messageLengthMI1 = this.messageMI1.length;
    },
    countMI2() {
      this.messageLengthMI2 = this.messageMI2.length;
    },
    countMI3() {
      this.messageLengthMI3 = this.messageMI3.length;
    },
    // Apply
    addApplyStep() {
      this.applyStep++;
    },
    minusApplyStep() {
      this.applyStep--;
    },
    resetApplyStep() {
      this.applyStep = 0;
    },
    // 로그인 체크
    checkLogin() {
      if (this.$store.state.isLogin === false) {
        alert('로그인을 먼저 진행해주세요');
        this.$router.push({ name: 'Home' }, function () {
          console.log('Home 호출 완료');
        });
      }
    },
    // 비동기 전송
    async apply() {
      await axios
        .post('https://www.yewon-remnant.net/api/applications', {
          c: this.messageC,
          v: this.messageV,
          d: this.messageD,
          i: this.messageI,
          p: this.messageP,
          specialty: this.messageMI1,
          specialty_details: this.messageMI2,
          performance: this.messageMI3,
          user_id: this.$store.state.loginUserId,
          scholarship_id: 1,
          application_type: this.applicationType,
          attendance: 'F',
          suggestion: 0,
        })
        .then(function (res) {
          console.log(res);
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    // 단계별 체크 & 커밋
    checkSelectApplyType() {
      if (this.applicationType === '') {
        alert('장학금 유형을 선택해주세요');
        return;
      }
      this.addApplyStep();
    },
    checkSelectApplyStapA() {
      if (this.messageLengthC < 1) {
        this.showAlert();
        return;
      }
      if (this.messageLengthV < 1) {
        this.showAlert();
        return;
      }
      if (this.messageLengthD < 1) {
        this.showAlert();
        return;
      }
      if (this.messageLengthI < 1) {
        this.showAlert();
        return;
      }
      if (this.messageLengthP < 1) {
        this.showAlert();
        return;
      }
      if (this.applicationType == 'A') {
        // 여기다가 DB 송신하는 로직 작성
        this.apply();
        this.finishApply();
        this.resetApplyStep();
        this.$router.push({ name: 'Home' }, function () {
          console.log('/board/${no} 호출 완료');
        });
      } else {
        this.addApplyStep();
      }
    },

    checkSelectApplyStapB() {
      if (this.messageLengthMI1 < 1) {
        console.log('여기 들어오나?');
        this.showAlert();
        return;
      } else {
        if (this.messageLengthMI2 < 1) {
          console.log('여기 들어오나?');
          this.showAlert();
          return;
        } else {
          if (this.messageLengthMI3 < 1) {
            console.log('여기 들어오나?');
            this.showAlert();
            return;
          } else {
            // 여기다가 DB 송신하는 로직 작성
            this.apply();
            this.finishApply();
            this.resetApplyStep();
            this.$router.push({ name: 'Home' }, function () {
              console.log('/board/${no} 호출 완료');
            });
          }
        }
      }
    },

    // 데이터 넣기
  },
  mounted() {
    this.checkLogin();
    this.resetApplyStep();
    this.chaingeTopNavNone();
    this.chaingeFooterNone();
  },
};
</script>

<style lang="scss">
@import '../design/init.scss';
@import '../design/_variables.scss';
@import '../design/_mixins.scss';
@import '../design/_functions.scss';

div.apply-container-box {
  section.apply-content {
    z-index: -10;
    @include setBox(1200px, auto, 0, 0, auto, none);
    padding-bottom: 100px;

    // From1. 기본형
    article.apply-defalt-from {
      // @include debugRed();
      @include setBox(1200px, 600px, 0, 0, 0, $bg-lv-06);
      margin-top: 70px;
      display: grid;
      grid-template-rows: 70px 1fr;
      // Title : 타이틀 박스
      hgroup {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, none);
        box-shadow: 2px 2px 2px 2px rgba(224, 224, 224, 0.671);
        display: grid;
        grid-template-columns: 300px 1fr;
        z-index: 1;
        h2 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-left: 20px;
          padding-top: 27px;
          @include setFont($font-content-sub, 500, $text-lv-01);
        }
        h3 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-right: 20px;
          padding-top: 27px;
          @include setFont($font-content-mini, 400, $text-lv-05);
          text-align: right;
        }
      }
      //Conent : 컨텐츠 박스
      section {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, none);
        // padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    // Apply Step : 0
    // Apply Step : 0-1
    article.apply-step-0-1 {
      // @include debugRed();
      @include setBox(1200px, 1070px, 0, 0, 0, $bg-lv-06);
      margin-top: 70px;
      display: grid;
      grid-template-rows: 1070px 1fr;
      // Title : 타이틀 박스
      hgroup {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
        box-shadow: 2px 2px 2px 2px rgba(224, 224, 224, 0.671);
        z-index: 1;
        img {
          // @include debugBlue();
          @include setBox(158px, 158px, 0, 0, auto, $bg-lv-06);
          margin-top: 100px;
        }
        h2 {
          // @include debugBlue();
          @include setBox(auto, auto, 0, 0, auto, $bg-lv-06);
          @include setFont($font-main-data, 500, $text-lv-01);
          text-align: center;
          margin-top: 16px;
        }
        h3 {
          // @include debugBlue();
          @include setBox(auto, auto, 0, 0, auto, $bg-lv-06);
          @include setFont($font-content-sub, 400, $text-lv-05);
          text-align: center;
          margin-top: 5px;
          margin-bottom: 30px;
        }
        img.tempImage {
          @include setBox(100%, auto, 50px, 20px, auto, $bg-lv-06);
          @include setFont($font-content-sub, 400, $text-lv-05);
          text-align: center;
          margin-top: 5px;
          margin-bottom: 20px;
        }
        P.apply-select-box {
          @include setBox(60%, 40px, 0, 0, auto, none);

          text-align: center;
          margin-top: 5px;

          select {
            @include setBox(100%, 100%, 10px, 0, auto, $ywr-green);
            @include setFont($font-content-sub, 400, $text-lv-02);
            text-align: center;
            border: 2px solid gainsboro;
            option {
              @include setBox(100%, 100%, 10px, 0, auto, $ywr-green);
            }
          }
        }
        p {
          // @include debugBlue();
          @include setBox(auto, auto, 0, 0, auto, $bg-lv-06);
          @include setFont($font-content-mini, 500, $ywr-blue);
          text-align: center;
          margin-top: 30px;
          line-height: 20px;
        }
      }
      //Conent : 컨텐츠 박스
      div.reg-rt-a1-b2 {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, auto, $bg-lv-06);
        // padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 170px;
        padding-right: 170px;

        //컬럼 2 : 회원가입 정보 입력
        div.reg-rt-a1-b2-c2 {
          // @include debugRed();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-top: 50px;
          display: grid;
          grid-template-rows: 1fr 150px;
          div.reg-rt-a1-b2-c2-d1 {
            // @include debugBlue();
            @include setBox(100%, 100%, 0, 0, 0, none);
            display: grid;
            grid-template-columns: 1fr 1fr;
            // 컬럼2-로우1-컬럼1 : 좌상단
            div.reg-rt-a1-b2-c2-d1-e1 {
              // @include debugRed();
              @include setBox(100%, 100%, 0, 0, 0, none);
              p {
                // @include debugBlue();
                @include setBox(auto, auto, 0, 0, 0, none);
                padding-top: 10px;
                label {
                  // @include debugBlue();
                  @include setInlineBox(130px, 28px, 0, 0, 3px, $bg-lv-06);
                  padding: 12px 0 0 10px;
                  margin-left: 30px;
                  vertical-align: middle;
                  @include setFont($font-content-basic, 500, $text-lv-02);
                }
                input {
                  // @include debugBlue();
                  @include setBox(200px, 40px, 0, 0, 0, $bg-lv-00);
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  //폰트 관련 처리
                  @include setFont($font-content-mini, 400, $text-lv-02);
                }
              }
            }
            // 컬럼2-로우1-컬럼1 : 우상단
            div.reg-rt-a1-b2-c2-d1-e2 {
              // @include debugRed();
              @include setBox(100%, 100%, 0, 0, 0, none);
              p {
                // @include debugBlue();
                @include setBox(auto, auto, 0, 0, 0, none);
                padding-top: 10px;
                label {
                  // @include debugBlue();
                  @include setInlineBox(130px, 28px, 0, 0, 3px, $bg-lv-06);
                  padding: 12px 0 0 10px;
                  margin-left: 30px;
                  vertical-align: middle;
                  @include setFont($font-content-basic, 500, $text-lv-02);
                }
                input {
                  // @include debugBlue();
                  @include setBox(200px, 40px, 0, 0, 0, $bg-lv-00);
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  @include setFont($font-content-mini, 400, $text-lv-02);
                }
                select {
                  // @include debugBlue();
                  @include setBox(200px, 40px, 0, 0, 0, $bg-lv-00);
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  //폰트 관련 처리
                  @include setFont($font-content-mini, 400, $text-lv-02);
                  //태그 관련 처리
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  appearance: none;
                  // background-image: url(../../image/reg-rt-option-bg.png);
                  object-fit: contain;
                }
                option {
                  //디버깅 옵션
                  // @include debugBlue();
                  //블록 관련 처리
                  @include setBox(200px, 40px, 0, 0, 0, rgba(0, 0, 0, 0));
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  //폰트 관련 처리
                  @include setFont($font-content-mini, 400, $text-lv-02);
                  //태그 관련 처리
                }
              }
            }
          }
          //컬럼2-로우2 : 하단
          div.reg-rt-a1-b2-c2-d2 {
            // @include debugRed();
            @include setBox(100%, 100%, 0, 0, 0, none);
            div.reg-rt-a1-b2-c2-d2-e1 {
              // @include debugRed();
              @include setBox(auto, auto, 0, 0, 0, none);
              p {
                // @include debugRed();
                @include setBox(auto, auto, 0, 0, 0, none);
                padding-top: 10px;
                label {
                  // @include debugBlue();
                  @include setInlineBox(130px, 28px, 0, 0, 3px, $bg-lv-06);
                  padding: 12px 0 0 10px;
                  margin-left: 30px;
                  vertical-align: middle;
                  @include setFont($font-content-basic, 500, $text-lv-02);
                }
                input {
                  //디버깅 옵션
                  // @include debugBlue();
                  //블록 관련 처리
                  @include setBox(409px, 40px, 0, 0, 0, $bg-lv-00);
                  padding: 0 0 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border: 1px solid gainsboro;
                  border-radius: 5px;
                  //폰트 관련 처리
                  @include setFont($font-content-mini, 400, $text-lv-02);
                }
                input:nth-child(3) {
                  width: 200px;
                  margin-left: 20px;
                }
              }
            }
            div.reg-rt-a1-b2-c2-d2-e2 {
              // @include debugRed();
              @include setBox(auto, auto, 0, 0, 0, none);
              p {
                // @include debugRed();
                @include setBox(auto, auto, 0, 0, 0, none);
                padding-top: 30px;
                label:first-child {
                  // @include debugBlue();
                  @include setInlineBox(130px, 28px, 0, 0, 3px, $bg-lv-06);
                  padding: 12px 0 0 10px;
                  margin-left: 30px;
                  vertical-align: middle;
                  @include setFont($font-content-basic, 500, $text-lv-02);
                }
                label {
                  // @include debugBlue();
                  @include setInlineBox(auto, auto, 0, 0, 10px, $bg-lv-06);
                  // padding: 12px 0 0 10px;
                  margin-left: 45px;
                  vertical-align: middle;
                  @include setFont($font-content-tiny, 500, $text-lv-02);
                }
                input {
                  @include setInlineBox(20px, 20px, 0, 0, 3px, $bg-lv-06);
                  vertical-align: middle;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
    // Apply Step : 0-2
    article.apply-step-0-2 {
      // @include debugRed();
      @include setBox(1200px, 600px, 0, 0, 0, $bg-lv-06);
      margin-top: 70px;
      display: grid;
      grid-template-rows: 70px 1fr;
      // Title : 타이틀 박스
      div.reg-rt-a2-b1 {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
        box-shadow: 2px 2px 2px 2px rgba(224, 224, 224, 0.671);
        display: grid;
        grid-template-columns: 300px 1fr;
        z-index: 1;
        div.reg-rt-a2-b1-c1 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
          padding-left: 20px;
          padding-top: 23px;
          @include setFont($font-content-sub, 500, $text-lv-01);
        }
        div.reg-rt-a2-b1-c2 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
          padding-right: 20px;
          padding-top: 23px;
          @include setFont($font-content-mini, 400, $text-lv-05);
          text-align: right;
        }
      }
      div.reg-rt-a2-b2 {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, $bg-lv-06);
        padding-bottom: 20px;
        padding: 55px;
        div.reg-rt-a2-b2-c1 {
          //디버깅 옵션
          // @include debugGreen();
          //블록 관련 처리
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          div {
            //디버깅 옵션
            // @include debugBlue();
            //블록 관련 처리
            @include setBox(auto, 30px, 0, 0, auto, $bg-lv-06);
            padding-top: 10px;
            //폰트 관련 처리
            @include setFont($font-content-basic, 500, $text-lv-02);
            vertical-align: middle;
          }
        }
        div.reg-rt-a2-b2-c2 {
          //디버깅 옵션
          // @include debugBlue();
          margin-top: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          p {
            //디버깅 옵션
            // @include debugRed();
            padding-left: 25px;
            input {
              //디버깅 옵션
              @include debugBlue();
              //블록 관련 처리
              @include setBox(100%, 40px, 0, 0, 0, $bg-lv-06);
              padding: 0 0 0 10px;
              display: inline-block;
              vertical-align: middle;
              border: 1px solid gainsboro;
              border-radius: 5px;
              //폰트 관련 처리
              @include setFont($font-content-mini, 400, $text-lv-02);
            }
            select {
              //디버깅 옵션
              // @include debugBlue();
              //블록 관련 처리
              @include setBox(100%, 40px, 0, 0, 0, $bg-lv-00);
              padding: 0 0 0 10px;
              display: inline-block;
              vertical-align: middle;
              border: 1px solid gainsboro;
              border-radius: 5px;
              //폰트 관련 처리
              @include setFont($font-content-mini, 400, $text-lv-02);
              //태그 관련 처리
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              // background-image: url(../../image/reg-rt-option-bg.png);
              object-fit: contain;
            }
          }
        }
      }
    }

    // Apply Step : 1
    // Apply Step : 1-1
    article.apply-step-1-1 {
      // @include debugRed();
      @include setBox(1200px, 70px, 0, 0, 0, $ywr-blue);
      margin-top: 70px;
      // Title : 타이틀 박스
      hgroup {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, none);
        box-shadow: 2px 2px 2px 2px rgba(224, 224, 224, 0.671);
        display: grid;
        grid-template-columns: 300px 1fr;
        z-index: 1;
        h2 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-left: 20px;
          padding-top: 27px;
          @include setFont($font-content-sub, 500, $text-lv-06);
        }
        h3 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-right: 20px;
          padding-top: 30px;
          @include setFont($font-content-basic, 400, $text-lv-06);
          text-align: right;
        }
      }
    }
    // Apply Step : 1-2
    article.apply-step-1-2 {
      // @include debugRed();
      @include setBox(1200px, 500px, 0, 0, 0, $bg-lv-06);
      margin-top: 50px;
      display: grid;
      grid-template-rows: 70px 1fr;
      // Title : 타이틀 박스
      hgroup {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, none);
        box-shadow: 2px 2px 2px 2px rgba(224, 224, 224, 0.671);
        display: grid;
        grid-template-columns: 500px 1fr;
        z-index: 1;
        h2 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-left: 20px;
          padding-top: 27px;
          @include setFont($font-content-sub, 500, $text-lv-01);
        }
        h3 {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-right: 20px;
          padding-top: 27px;
          @include setFont($font-content-mini, 400, $text-lv-05);
          text-align: right;
        }
      }
      //Conent : 컨텐츠 박스
      section {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 30px, 0, none);
        // padding-top: 20px;
        padding-bottom: 60px;
        //컬럼 1 : 프로필 이미지 업로드
        textarea {
          // @include debugBlue();
          @include setBox(100%, 100%, 5px, 20px, 0, $bg-lv-00);
          border: 2px solid #e6e6e6;
          resize: none;
          @include setFont($font-content-basic, 400, $text-lv-02);
          line-height: 25px;
        }
        span {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, none);
          padding-top: 15px;
          padding-right: 5px;
          @include setFont($font-content-basic, 400, $text-lv-05);
          text-align: right;
        }
        .enoughLength {
          color: $ywr-green;
        }
        .notEnoughLength {
          color: $ywr-red;
        }
      }
    }
    // Apply Step : 1-3
    article.apply-step-1-3 {
      // @include debugRed();
      @include setBox(1200px, 200px, 0, 0, 0, $bg-lv-06);
      margin-top: 50px;
      display: grid;
      grid-template-rows: 150px 1fr;
      // Title : 타이틀 박스
      hgroup {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 0, 0, none);
        box-shadow: 2px 2px 2px 2px rgba(224, 224, 224, 0.671);
        display: grid;
        grid-template-columns: 1fr 300px;
        z-index: 1;

        div {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 0, 0, none);
          h2 {
            // @include debugBlue();
            @include setBox(auto, auto, 0, 0, 0, none);
            padding-left: 30px;
            padding-top: 60px;
            @include setFont($font-content-sub, 500, $text-lv-01);
          }
          p {
            // @include debugBlue();
            @include setBox(auto, auto, 0, 0, 0, none);
            padding-left: 30px;
            padding-top: 5px;
            @include setFont($font-content-mini, 400, $text-lv-05);
            text-align: left;
          }
        }
        a {
          // @include debugBlue();
          @include setBox(175px, 60px, 5, 0, 0, none);
          float: right;
          margin-top: 40px;
          margin-left: 55px;

          button {
            @include setBox(175px, 60px, 5, 0, 0, none);
            @include blueButton();
          }
        }
        button:active {
          @include blueButtonActive();
        }
      }
      //Conent : 컨텐츠 박스
      section {
        // @include debugBlue();
        @include setBox(100%, 100%, 0, 30px, 0, none);
        // padding-top: 20px;
        padding-bottom: 60px;
        //컬럼 1 : 프로필 이미지 업로드
        hgroup.apply-no-file {
          // @include debugBlue();
          @include setBox(100%, 100%, 0, 30px, 0, none);
          text-align: center;

          h5 {
            // @include debugBlue();
            @include setBox(auto, auto, 0, 5px, 0, none);
            @include setFont($font-content-mini, 400, $text-lv-02);
            margin-top: 70px;
          }

          h6 {
            // @include debugBlue();
            @include setBox(auto, auto, 0, 5px, 0, none);
            @include setFont($font-content-mini, 400, $text-lv-05);
          }
        }
        article.apply-yes-file {
          // @include debugBlue();
          @include setBox(100%, 100px, 0, 0, 0, $bg-lv-06);
          box-shadow: 2px 2px 2px 2px #c7c7c73d;
          display: grid;
          grid-template-columns: 100px 1fr 100px;
          margin-top: 10px;
          img:first-child {
            // @include debugRed();
            @include setBox(70px, 70px, 0, 0, 0, none);
            margin-top: 15px;
            margin-left: 15px;
          }
          header {
            // @include debugBlue();
            @include setBox(100%, 100%, 0, 0, 0, none);
            padding-top: 30px;
            padding-left: 30px;
            h3 {
              // @include debugBlue();
              @include setBox(auto, auto, 0, 0, 0, none);
              @include setFont($font-content-sub, 600, $text-lv-02);
            }
            h4 {
              // @include debugBlue();
              @include setBox(auto, auto, 0, 0, 0, none);
              @include setFont($font-content-basic, 400, $text-lv-05);
              margin-top: 5px;
            }
          }
          img {
            // @include debugBlue();
            @include setBox(50px, 50px, 0, 0, 0, none);
            margin-top: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  button {
    //디버깅 옵션
    // @include debugBlue();
    //블록 관련 처리
    @include setBox(40vw, 50px, 0, 0, auto, $text-lv-03);
    padding: 0 0 0 10px;
    margin-top: 80px;
    vertical-align: middle;
    border: 1px solid gainsboro;
    border-radius: 5px;
    //폰트 관련 처리
    @include setFont($font-content-basic, 400, $text-lv-06);
    @include blueButton();
  }
  button:active {
    @include blueButtonActive();
  }
}
</style>
