<template>
  <section class="index-container-box">
    <article>
      <header>
        <h1>예원서밋장학금</h1>
        <h2>Yewon Summit Scholarship</h2>
        <h3>차세대 선교사들을 위한 발판 예원서밋장학금</h3>
      </header>
      <section>
        <router-link to="/Apply">
          <a href="">
            <img src="../assets/images/index_apply_scholar@2x.png" alt="" />
          </a>
        </router-link>
      </section>
    </article>
    <article>
      <img src="../assets/images/index_about_scholar.png" alt="" />
    </article>
  </section>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'Home',
  props: {},
  methods: {
    ...mapMutations([
      // 로그인
      'loginSuccess',
      'chaingeTopNavSub',
      // 변경함수 : 탑 네비게이션 상태변수
      'chaingeTopNavMain',
      'chaingeTopNavSub',
      'chaingeTopNavLogin',
      'chaingeTopNavNone',
      // 변경함수 : 탑 네비게이션 서브 타이틀 변경
      'chaingeSubTitleSignUp',
      'chaingeSubTitleSignUpRt',
      // 변경함수 : 푸터 상태 변경
      'chaingeFooterMain',
      'chaingeFooterNone',
      // 변경함수 : 유저타입 상태변수
      'regUserTyepRT',
      'regUserTyepMI',
      'regUserTyepTH',
      'regUserTyepPR',
      'regUserTyepOF',
    ]),
  },
  mounted() {
    this.chaingeTopNavMain();
  },
};
</script>

<style lang="scss">
@import '../design/_variables.scss';
@import '../design/init.scss';
@import '../design/_mixins.scss';
@import '../design/_functions.scss';

section.index-container-box {
  // @include debugGreen();
  @include setBox(100%, auto, 0, 0, auto, $bg-lv-06);
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url('../assets/images/index_bg.png');
  article:nth-child(1) {
    // @include debugRed();
    @include setBox(1200px, 280px, 0, 0, auto, $bg-lv-01-op);
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    header {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 0, 0, nome);
      h1 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, nome);
        @include setFont($font-main-data, 500, $ywr-blue);
        margin-top: 70px;
        margin-left: 80px;
      }
      h2 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, nome);
        @include setFont($font-content-sub, 500, $text-lv-06);
        margin-top: 20px;
        margin-left: 80px;
      }
      h3 {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, 0, nome);
        @include setFont($font-content-sub, 400, $text-lv-05);
        margin-top: 10px;
        margin-left: 80px;
      }
    }
    section {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 50px, 0, nome);
      padding-left: 20px;
      padding-right: 100px;
      a {
        // @include debugRed();
        @include setBox(100%, 100%, 0, 0, 0, nome);
        img {
          // @include debugRed();
          @include setBox(100%, 100%, 0, 0, 0, nome);
        }
      }
    }
  }
  article:nth-child(2) {
    // @include debugRed();
    @include setBox(1200px, 520px, 0, 0, auto, $bg-lv-01-op);
    margin-top: 20px;
    img {
      // @include debugRed();
      @include setBox(100%, 100%, 0, 0, auto, none);
    }
  }
}
</style>
