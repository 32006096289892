<template>
  <section class="login-container-box">
    <article class="login-content">
      <header>
        <router-link
          @click="[chaingeTopNavMain(), chaingeFooterMain()]"
          style="text-decoration: none"
          to="/"
        >
          <img src="../assets/images/login_back_arrow@2x.png" alt="" />
        </router-link>
        <h3>HOME</h3>
      </header>
      <section class="login-inputs">
        <img src="../assets/images/login_logo@2x.png" alt="" />
        <p>
          <label for="email">이메일</label>
          <input
            type="email"
            name="email"
            v-model="email"
            key=""
            placeholder="email을 입력하세요"
          />
        </p>
        <p>
          <label for="password">비밀번호</label>
          <input
            type="password"
            name="password"
            v-model="password"
            placeholder="password를 입력하세요"
          />
        </p>
      </section>
      <footer>
        <button @click="login">LOGIN</button>
        <router-link to="/UserType">
          <h6>회원가입</h6>
        </router-link>
        <span>Copyright @ 2021 yewonremnant.com. All Reights Reservied</span>
      </footer>
    </article>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import axios from 'axios';
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapState([
      // 로그인
      'isLogin',
      'isLoginError',
      // 텝 네비게이션 상태변수
      'topNavStats',
      // 유저타입 상태변수
      'regUserType',
      //탑 네비게이션 : Sub 변수
      'topNavKoTitle',
      'topNavEnTitle',
    ]),
  },
  methods: {
    ...mapMutations([
      // 로그인
      'loginSuccess',
      'chaingeTopNavSub',
      // 변경함수 : 탑 네비게이션 상태변수
      'chaingeTopNavMain',
      'chaingeTopNavSub',
      'chaingeTopNavLogin',
      'chaingeTopNavNone',
      // 변경함수 : 탑 네비게이션 서브 타이틀 변경
      'chaingeSubTitleSignUp',
      'chaingeSubTitleSignUpRt',
      // 변경함수 : 푸터 상태 변경
      'chaingeFooterMain',
      'chaingeFooterNone',
      // 변경함수 : 유저타입 상태변수
      'regUserTyepRT',
      'regUserTyepMI',
      'regUserTyepTH',
      'regUserTyepPR',
      'regUserTyepOF',
    ]),
    async login() {
      // DB에서 입력한 Email을 가지고 정보를 가지고온다
      let userData = null;
      await axios
        .get('https://www.yewon-remnant.net/api/users')
        .then(function (res) {
          console.log(res);
          console.log(res.status);
          userData = res.data;
          console.log(userData);
        })
        .catch(function (err) {
          console.log(err);
          this.alertFailLogin();
          return false;
        });

      let loginSuccess = false;
      for (let i = 0; i < userData.length; i++) {
        console.log('반복문 진입');
        if (userData[i].email === this.email) {
          console.log('첫 if 통과');
          if (userData[i].password === this.password) {
            console.log(userData[i].id);
            console.log(this.$store.state.loginUserId);
            this.$store.state.loginUserId = userData[i].id;
            console.log('두번째 if 통과');
            alert('로그인 성공');
            loginSuccess = true;
            this.$store.state.isLogin = true;
            this.$router.push({ name: 'Apply' }, function () {
              console.log('Apply 호출 완료');
            });
            break;
          }
        }
      }

      if (loginSuccess === false) alert('로그인 정보를 확인하세요');
    },
    // Email로 가지고온 정보에서 패스워드와 사용자가 입력하 페스워드 비교

    // 맞으면 로그인 완료
    // 스토어에 있는 로그인 스테이터스를 트루로 바꾼다
    // 틀리면 여기서 쭉 있기
    // 비밀번호 찾기는 일단 지금은 문의하세요로 처리
    // DB에서 정보를 가지고온다
    mounted() {
      this.chaingeTopNavNone();
    },
  },
};
</script>

<style lang="scss">
@import '../design/init.scss';
@import '../design/_variables.scss';
@import '../design/_mixins.scss';
@import '../design/_functions.scss';

section.login-container-box {
  // @include debugRed();
  @include setBox(100vw, 100vh, 0, 0, auto, $bg-lv-00);
  margin-top: -140px;
  article.login-content {
    // @include debugRed();
    @include setBox(490px, 640px, 7px, 0, auto, $bg-lv-06);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -320px;
    margin-left: -245px;
    header {
      // @include debugRed();
      @include setBox(100%, 40px, 0, 0, auto, none);
      display: grid;
      grid-template-columns: 40px 1fr;
      img {
        // @include debugRed();
        @include setBox(12px, 20px, 0, 0, 0, none);
        margin-left: 15px;
        margin-top: 15px;
      }
      h3 {
        // @include debugRed();
        @include setBox(auto, 20px, 0, 0, 0, none);
        @include setFont($font-content-basic, 500, $text-lv-02);
        margin-top: 15px;
        padding-top: 3px;
      }
    }
    section.login-inputs {
      // @include debugRed();
      @include setBox(100%, auto, 0, 0, 0, none);
      img {
        // @include debugRed();
        @include setBox(162px, 187px, 0, 0, auto, none);
        margin-top: 60px;
        margin-bottom: 53px;
      }
      p {
        // @include debugRed();
        @include setBox(auto, auto, 0, 0, auto, none);
        padding-left: 30px;
        margin-top: 15px;
        display: grid;
        grid-template-columns: 3fr 7fr;
        label {
          // @include debugBlue();
          @include setInlineBox(100%, 28px, 0, 0, 3px, $bg-lv-06);
          padding-top: 10px;
          padding-left: 44px;
          vertical-align: middle;
          @include setFont($font-content-basic, 600, $text-lv-02);
        }
        input {
          // @include debugBlue();
          @include setBox(250px, 40px, 0, 0, 0, $bg-lv-00);
          padding: 0 0 0 20px;
          display: inline-block;
          vertical-align: middle;
          border: 1px solid gainsboro;
          border-radius: 5px;
          //폰트 관련 처리
          @include setFont($font-content-basic, 400, $text-lv-02);
        }
      }
    }
    footer {
      // @include debugBlue();
      @include setBox(100%, auto, 0, 0, 0, none);
      button {
        // @include debugBlue();
        @include setBox(70%, 40px, 5px, 0, auto, none);
        @include setFont($font-content-basic, 600, $text-lv-02);
        margin-top: 40px;
        @include greenButton();
      }
      button:active {
        @include greenButtonActive();
      }
      h6 {
        // @include debugBlue();
        @include setBox(100%, auto, 0, 0, 0, none);
        @include setFont($font-content-tiny, 500, $text-lv-03);
        text-align: center;
        margin-top: 20px;
      }
      span {
        // @include debugBlue();
        @include setBox(100%, auto, 0, 0, 0, none);
        @include setFont($font-content-tiny, 400, $text-lv-04);
        text-align: center;
        margin-top: 40px;
      }
    }
  }
}
</style>
